@import "@/style/common.scss";

.inputWrapper {
	@include column_sb;
	position: relative;
	.title {
		@include row_sb;
		font-size: 12px;
		height: 16px;
		line-height: 16px;
		label {
			color: $Gary_Color;
		}
		.link {
			color: $Active_Color;
			@include common_btn;
			span {
				color: #151515;
			}
			b {
				color: #f78c1c;
				display: inline-block;
				margin-left: 10px;
			}
		}
	}
	.inputBox {
		position: relative;
		margin-bottom: 24px;
		.emailAddress {
			position: absolute;
			width: 100%;
			z-index: 20;
			top: 60px;
			left: 0;
			@include boxShadow;
			li {
				width: 100%;
				height: 40px;
				line-height: 40px;
				padding-left: 24px;
				background: $white_color;
				@include common_btn;
				&:hover {
					background: #f5f5f5;
				}
			}
		}
		input {
			width: 100%;
			height: rem(54px);
			outline: none;
			background: #f5f5f5;
			line-height: rem(54px);
			font-size: rem(16px);
			padding: 0 24px;
			border: none;
			border-radius: 2px;
			margin-top: 5px;
			&::placeholder {
				font-size: 14px;
				color: $Gary_Color;
			}
		}
		.eye {
			position: absolute;
			width: 20px;
			height: 15px;
			bottom: rem(18px);
			right: rem(18px);
			z-index: 10;
			img {
				width: 25px;
				height: 20px;
			}
		}
	}
	.error {
		position: absolute;
		left: 0;
		bottom: 5px;
		font-size: 12px;
		line-height: 14px;
		color: $raise;
	}
}
