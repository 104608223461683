@import "@/style/common.scss";

.fundsDetail {
	@include column_sb;
	flex: 1;
	width: 100%;
	height: 100%;
	.fundsTitle {
		height: 93px;
		padding: 24px;
		@include row_sb;
		background: $white_color;
		@include boxShadow;
		h5 {
			font-size: rem(32px);
			line-height: 38px;
		}
	}
	.fundsMain {
		flex: 1;
		margin-top: 10px;
		background: $white_color;
		padding: 24px;
		@include boxShadow;
	}
}

.detailContent {
	.title {
		display: flex;
		height: 49px;
		.dateBox {
			@include row_start;
			color: $font_color;
			font-size: 12px;
			margin-right: 20px;
			.subTitle {
				color: $Deep_Section_Bg;
				width: rem(50px);
				line-height: rem(57px);
			}
			.dateWrapper {
				@include row_sb;
				span {
					align-self: center;
					&.line {
						width: 30px;
						text-align: center;
					}
				}
			}
		}
		.searchButtonGroup {
			display: flex;
			align-self: center;
			.quickSearchBox {
				align-self: center;
				.quickSearch,
				.searchButton {
					width: rem(60px);
					text-align: center;
					height: rem(38px);
					line-height: rem(38px);
					border: 1px solid #f5f5f5;
					color: $font_color;
					margin-right: rem(15px);
					border-radius: 4px;
					font-size: 12px;
					cursor: pointer;
				}
				.quickSearch:hover {
					background: $Active_Color;
					border-color: $Active_Color;
					color: $white_color;
				}
				.searchButton {
					width: rem(80px);
					background: $Active_Color;
					color: $white_color;
					margin: 0;
				}
				.active {
					background: $Active_Color;
					color: $white_color;
				}
			}
		}
	}
	ol {
		width: 100%;
		height: 484px;
		li {
			display: flex;
			height: 44px;
			line-height: 44px;
			justify-content: space-between;
			padding: 0 16px;
			font-size: 14px;
			border-bottom: 1px solid #f5f5f5;
			&.header {
				background: #f5f5f5;
				color: $Gary_Color;
				font-size: 12px;
			}
			div {
				flex: 1;
				&.status {
					text-align: center;
				}
				&:last-child {
					text-align: right;
				}
			}
		}
		&.tradeList {
			li {
				div {
					flex: none;
					width: 10.4%;
				}
				.long {
					width: 12%;
				}
				.longer {
					width: 18%;
				}
				.income {
					padding-left: 30px;
				}
			}
		}
		&.eagleList {
			li div {
				&:last-child {
					text-align: left;
				}
			}
		}
	}
}
