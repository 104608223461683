@import "@/style/common.scss";

.downloadWrapper {
	background: #f5f5f5;
	display: block;
	header {
		background: rgba(0, 0, 0, 0.3);
		position: absolute;
		width: 100%;
	}
	.section1 {
		height: auto;
		display: flex;
		position: relative;
		background: linear-gradient(to right, #040404, #1d1d1e);
		> img {
			position: absolute;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
			margin: auto;
			height: 100%;
		}
		.main {
			position: relative;
			z-index: 1;
			align-self: center;
			width: 1000px;
			margin: auto;
			padding: rem(160px) 0;
			h4,
			p {
				@include row_center;
				justify-content: flex-start;
			}
			h4 {
				height: 67px;
				font-size: 48px;
				font-weight: 200;
				color: $white_color;
				line-height: 67px;
			}
			p {
				height: 40px;
				font-size: 28px;
				font-weight: 600;
				color: $white_color;
				line-height: 40px;
			}
			.btnBox {
				display: flex;
				justify-content: flex-start;
				padding-top: 40px;
				div {
					width: 180px;
					height: 50px;
					margin-right: 20px;
					@include common_btn;
					&.android {
						background: url("../../assets/download/android.png") no-repeat center;
						background-size: 100%;
						&:hover {
							background: url("../../assets/download/and_hover.png") no-repeat center;
							background-size: 100%;
						}
					}
					&.ios {
						background: url("../../assets/download/ios.png") no-repeat center;
						background-size: 100%;
						&:hover {
							background: url("../../assets/download/ios_hover.png") no-repeat center;
							background-size: 100%;
						}
					}
				}
				img {
					width: 50px;
					height: 50px;
					align-self: center;
					cursor: pointer;
				}
			}
		}
	}
	.section2,
	.section3 {
		width: 100%;
		height: 600px;
		background: $white_color;
		margin-bottom: 32px;
		.main {
			height: 100%;
			display: flex;
			width: $maxWidth;
			margin: auto;
			> div {
				flex: 1;
				display: flex;
				justify-content: center;
			}
			.left {
				justify-content: center;
				align-self: center;
				width: 350px;
				height: 500px;
				&.pc {
					width: 546px;
					height: 400px;
				}
			}
			.right {
				width: 360px;
				height: 400px;
			}
		}
	}

	.section4 {
		height: 460px;
		background: $white_color;
		margin-bottom: 100px;
		.main {
			width: $maxWidth;
			margin: auto;
			@include column_sb;
			padding-top: 60px;
			img {
				&:first-child {
					height: 58px;
					width: 600px;
					margin: 0 auto 60px;
				}
			}
		}
	}
}
.downloadQR {
	width: 110px;
	height: 110px;
	background: $white_color;
	margin-top: 4px;
	img {
		width: 110px;
		height: 110px;
	}
}
