//颜色变量
$raise: #ff5f57;
$fall: #21bf73;
$white_color: #fff;
$font_color: #b2b2b2;
$light_font_color: #7c8185;
$Gary_Color: #858585;
$Deep_Gary_Color: #333;
$Black_Font_Color: #151515;
$Active_Color: rgba(247, 140, 28, 1);
$Light_Active: #f7ce66;
$Black_Bg: #0d0e12;
$Deep_Section_Bg: #202020;
$Line_Color: rgba(255, 255, 255, 0.05);

$maxWidth: 1200px;
$baseWidth: 1920;
$baseHeight: 946;
$baseSize: $baseWidth/100;

@function rem($val) {
	@if unitless($val) ==true {
		@return #{$val/$baseSize}rem;
	} @else {
		@return #{($val / ($val * 0 + 1)) / $baseSize}rem;
	}
}

@mixin screen($res-max) {
	@media only screen and (max-width: #{$res-max}px),
  only screen and (max-device-width: #{$res-max}px) {
		html,
		body {
			font-size: #{($baseSize * ($res-max/$baseWidth))}px !important;
		}
	}
}

//mixins 混合
@mixin flex-column() {
	display: flex;
	flex-direction: column;
}
@mixin column_sb() {
	@include flex-column;
	justify-content: space-between;
}
@mixin column_center() {
	@include flex-column;
	justify-content: center;
}
@mixin column_start() {
	@include flex-column;
	justify-content: flex-start;
}
@mixin column_end() {
	@include flex-column;
	justify-content: flex-end;
}
@mixin flex-row() {
	display: flex;
	align-items: center;
}
@mixin row_sb {
	@include flex-row;
	justify-content: space-between;
}
@mixin row_center {
	@include flex-row;
	justify-content: center;
}
@mixin row_start {
	@include flex-row;
	justify-content: flex-start;
}
@mixin row_end {
	@include flex-row;
	justify-content: flex-end;
}

@mixin button(
	$width: 100,
	$height: 30,
	$bg: $main_color,
	$color: $main_font_color,
	$border-color: $main_color,
	$font-size: 14
) {
	width: rem($width);
	height: rem($height);
	background: $bg;
	color: $color;
	border: 1px solid $border-color;
	text-align: center;
	line-height: rem($height - 2);
	border-radius: rem(3);
	font-size: rem($font-size);
	margin: auto;
}

@mixin boxShadow() {
	box-shadow: 1px 2px 4px 0px rgba(0, 0, 0, 0.08);
}

/**字体*/
@mixin fontDM {
	font-family: "DIN,DIN-Medium" !important;
}

@mixin fontDB {
	font-family: "DIN,DIN-Bold" !important;
}

#root {
	font-family: "cereal", PingFang SC, Noto Sans SC, Microsoft YaHei, NSimSun, Ping, arial, Menlo, Monaco, Consolas,
		Courier New, monospace, sans-serif;
	height: 100%;
	&.zh-TW {
		font-family: "cereal", "PingFang SC", "Noto Sans TC", "Microsoft YaHei", sans-serif;
	}
	&.vi-VN {
		font-family: "Source Sans Pro", "Microsoft YaHei", sans-serif;
	}
	&.ru-RU {
		font-family: "cereal", "Circular", "Microsoft YaHei", sans-serif;
	}
}

//antd样式
.ant-select {
	width: 100%;
	align-self: center;
	margin-top: 5px;
	&:hover {
		.ant-select-selector {
			border-color: $Active_Color !important;
		}
		.ant-select-arrow {
			.anticon svg {
				fill: $Active_Color !important;
			}
		}
	}
	.ant-select-arrow {
		transition: transform 0.3s ease-out;
	}
	&-open {
		@keyframes rotate {
			0% {
				transform: rotate(0);
			}
			100% {
				transform: rotate(180deg);
			}
		}
		.ant-select-arrow {
			animation: rotate 0.3s ease-in;
			transform: rotate(180deg);
		}
	}
	.ant-select-selector {
		border: 1px solid #f5f5f5 !important;
		height: rem(54px) !important;
		line-height: rem(54px);
		box-shadow: none !important;
		&:focus {
			box-shadow: none !important;
		}
		.ant-select-selection__rendered {
			line-height: rem(54px);
			display: flex;
		}
		.ant-select-arrow {
			top: 45%;
		}
		.anticon {
			width: 20px;
			height: 16px;
			svg {
				width: 100%;
				height: 100%;
			}
		}
	}
	.ant-select-selection-selected-value {
		display: flex !important;
		height: 30px;
		align-self: center;
		img {
			align-self: center;
			margin: 5px;
			width: 20px;
			height: 20px;
		}
		b {
			align-self: center;
		}
		span {
			align-self: center;
		}
		.default {
			display: flex;
		}
	}
}
.simple_select_box {
	width: 100%;
	height: rem(54px);
	outline: none;
	background: #f5f5f5;
	line-height: rem(54px);
	font-size: 16px;
	// padding: 0 14px;
	border: none;
	border-radius: 2px;
	margin-top: 5px;
	// position: relative;
	.ant-select-selector {
		position: relative;
		@include row_sb;
		height: 100%;
		border: none;
		background: #f5f5f5 !important;
		padding-left: 24px !important;
	}
	.ant-select-arrow {
		right: 24px;
		.anticon svg {
			fill: rgba(0, 0, 0, 0.25) !important;
		}
	}
	&:hover {
		.ant-select-selector {
			border-color: #f5f5f5 !important;
		}
		.ant-select-arrow {
			.anticon svg {
				fill: rgba(0, 0, 0, 0.25) !important;
			}
		}
	}
}
.ant-select-dropdown {
	padding: 0;
	border-radius: 0;
	z-index: 10003;
	.ant-select-item-option-selected {
		border-radius: 0;
		background: $Active_Color !important;
	}
	.ant-select-item {
		height: 40px;
		line-height: 30px;
		display: flex;
	}
}
.ant-tabs-nav-list {
	.ant-tabs-tab {
		padding: 12px 0;
		&-active {
			.ant-tabs-tab-btn {
				color: #f78c1c !important;
				text-shadow: 0 0 0.25px #f78c1c !important;
			}
		}
		&:hover {
			color: #f78c1c !important;
		}
	}
	.ant-tabs-ink-bar {
		background: #f78c1c;
	}
}
.ant-picker {
	height: 32px;
	&:hover {
		border-color: #f78c1c !important;
	}
	&-focused {
		border: 0;
	}
}
.ant-carousel {
	@include row_center;
	.notice_box {
		width: 272px;
		color: #c0c2cb;
		margin: 0 12px;
		p {
			// line-height: 64px;
			text-overflow: ellipsis;
			white-space: nowrap;
			// overflow: hidden;
		}
	}
	// @include row_sb;
}

.pagination {
	padding: 10px 20px;
	display: flex;
	justify-content: flex-end;
	color: #f5f5f5;
	.ant-pagination-total-text {
		color: $font_color;
	}
	.ant-pagination-item {
		background: transparent;
		color: $font_color;
		border: #f5f5f5;
		a {
			color: $font_color;
		}
		&:hover a {
			color: $Active_Color;
			border-color: $Active_Color;
		}
	}

	.ant-pagination-item-active {
		border-color: $Active_Color;
		color: $Active_Color;
		a {
			color: $Active_Color;
		}
	}
	.ant-pagination-next,
	.ant-pagination-prev {
		&:hover a {
			border-color: $Active_Color;
			svg {
				fill: $Active_Color;
			}
		}
	}
	.ant-pagination-item-link {
		background: transparent;
		border-color: #f5f5f5;
		svg {
			fill: $font_color;
		}
	}
	.ant-pagination-options {
		.ant-select-selector {
			@include row_sb;
			height: 32px !important;
		}
	}
}
.announce-pagination {
	.ant-pagination-next,
	.ant-pagination-prev {
		border-radius: 0;
		background-color: #d8d8d8;
		color: #323232;
		height: 40px;
		min-width: 60px;
		line-height: 38px;
		margin: 0 5px;
		font-family: PingFangTC, PingFangTC-Regular;
	}
	.ant-pagination-item {
		border-radius: 0;
		background-color: #d8d8d8;
		color: #323232;
		height: 40px;
		min-width: 28px;
		line-height: 38px;
		margin: 0 5px;
		&:focus,
		&:hover {
			border: 1px #f78c1c solid;
			& > a {
				color: #ffffff;
			}
		}
	}
	.ant-pagination-item-active,
	.ant-pagination-item-active:focus,
	.ant-pagination-item-active:hover {
		background-color: #f78c1c;
		border: 1px #f78c1c solid;
	}

	.ant-pagination-item-active a {
		color: #ffffff;
	}
}
.select_box {
	width: 150px;
	align-self: center;
	margin: 0;
	padding: 0;
	margin-right: 20px;

	.ant-select-selector {
		border: 1px solid #f5f5f5;
		height: rem(38px) !important;
		line-height: rem(38px) !important;
		&:focus {
			box-shadow: none;
		}
	}
	&:hover {
		.ant-select-selector {
			border-color: #f5f5f5 !important;
		}
		.ant-select-arrow {
			.anticon svg {
				fill: #bbb !important;
			}
		}
	}
}
.trade-tab {
	.ant-tabs-nav {
		margin: 0;
		border: none;
		height: 36px;
		.ant-tabs-tab {
			margin: 0 !important;
			color: #c0c2cb;
			font-size: rem(16px);
			padding: 12px 16px;
			svg {
				fill: #c0c2cb;
				max-width: 48px;
				height: 12px;
			}
			&-active {
				svg {
					fill: #f78c1c;
				}
			}
		}
		.ant-tabs-ink-bar {
			display: none;
		}
	}
}
.trade-switch {
	&.ant-switch-checked {
		background: #f78c1c !important;
	}
}
.ant-slider {
	.ant-slider-rail {
		background: #575757;
	}
	.ant-slider-track {
		background: #f78c1c;
	}
	.ant-slider-step {
		.ant-slider-dot {
			background: #575757;
			border: #333;
			&-active {
				background: #f78c1c;
			}
		}
	}
	.ant-slider-mark {
		.ant-slider-mark-text {
			color: #858585;
			&-active {
				color: $white_color;
			}
		}
	}
	.ant-slider-handle {
		background: #f78c1c;
		border: #f78c1c;
		border-radius: 50%;
		&::before,
		&::after {
			display: none;
		}
	}
	&:hover {
		.ant-slider-rail {
			background: #575757;
		}
		.ant-slider-track {
			background: #f78c1c;
		}
	}
	&.trade-setting {
		width: 80%;
		&.stopProfit {
			.ant-slider-track {
				background: $raise;
			}
			.ant-slider-handle {
				background: #fff;
				border: 1px solid $raise;
				border-radius: 50%;
			}
		}
		&.stopLoss {
			.ant-slider-track {
				background: $fall;
			}
			.ant-slider-handle {
				background: #fff;
				border: 1px solid $fall;
				border-radius: 50%;
			}
		}
	}
}

.ant-checkbox-wrapper {
	.ant-checkbox-checked {
		&::after {
			border: 0;
		}
		.ant-checkbox-inner {
			background: #f78c1c;
			border-color: #f78c1c;
		}
	}
	&:hover {
		.ant-checkbox-checked {
			.ant-checkbox-input,
			.ant-checkbox-inner {
				background: #f78c1c !important;
				border-color: #f78c1c !important;
			}
			&::after {
				border: 0;
			}
		}
	}
}
.ant-message,
.ant-notification {
	z-index: 99999;
}

// .adm-list-default .adm-list-body{
//   border-bottom:solid 1px $line_color !important;
// }
@mixin un-select {
	-webkit-touch-callout: none;
	-webkit-user-select: none;
	-khtml-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
	cursor: default;
}
@mixin common_btn {
	@include un-select();
	cursor: pointer;
}
@mixin confirm_btn {
	background: $Active_Color;
	color: $white_color;
	text-align: center;
	height: 48px;
	line-height: 48px;
	border-radius: 2px;
	@include common_btn;
	&:hover {
		background: $Light_Active;
	}
	&.error {
		background: #f5f5f5;
		color: $Gary_Color;
	}
	&.verify {
		background: #f5f5f5;
		color: $Black_Font_Color;
	}
}

@mixin triangle($direction, $size, $tend, $color) {
	@content;
	width: 0;
	height: 0;
	@if $direction == top {
		border-bottom: $tend solid $color;
		border-left: $size solid transparent;
		border-right: $size solid transparent;
	} @else if $direction == right {
		border-left: $tend solid $color;
		border-top: $size solid transparent;
		border-bottom: $size solid transparent;
	} @else if $direction == bottom {
		border-top: $tend solid $color;
		border-left: $size solid transparent;
		border-right: $size solid transparent;
	} @else if $direction == left {
		border-right: $tend solid $color;
		border-top: $size solid transparent;
		border-bottom: $size solid transparent;
	}
}

@mixin screen($res-max) {
	@media only screen and (min-width: #{$res-max}px) {
		html,
		body {
			font-size: #{($baseSize * ($res-max/$baseWidth))}px !important;
		}
	}
}
@mixin active_btn($background, $border, $color) {
	background: $background;
	border-top: 2px solid $border;
	color: $color;
}

@include screen(1366);
@include screen(1400);
@include screen(1440);
@include screen(1600);
@include screen(1680);
@include screen(1900);
@include screen(1920);
@include screen(1960);

.green {
	color: $fall !important;
}

.red {
	color: $raise !important;
}

.rest {
	color: $Gary_Color !important;
}

.red_bg {
	background: $raise !important;
}
.yellow_bg {
	background: $Active_Color !important;
}
.green_bg {
	background: $fall !important;
}
.confirm_btn {
	background: $Active_Color;
	color: $white_color;
	text-align: center;
	height: 48px;
	line-height: 48px;
	border-radius: 2px;
	@include common_btn;
	&:hover {
		background: $Light_Active;
	}
	&.error {
		background: #f5f5f5;
		color: $Gary_Color;
	}
	&.disable {
		background: #f5f5f5;
		color: $Black_Font_Color;
	}
}

/* 设置滚动条的样式 */
::-webkit-scrollbar {
	width: 2px;
	height: 3px;
	//display: none;
}

/* 滚动槽 */
::-webkit-scrollbar-track {
	-webkit-box-shadow: inset 0 0 6px $Deep_Section_Bg;
	border-radius: 10px;
}

/* 滚动条滑块 */
::-webkit-scrollbar-thumb {
	border-radius: 10px;
	background: #bbb;
	-webkit-box-shadow: inset 0 0 6px $Deep_Section_Bg;
}

a {
	text-decoration: none;
	color: $white_color;
}

* {
	text-decoration: none;
	box-sizing: border-box;
	margin: 0;
	padding: 0;
	// font-family: arial, Menlo, Monaco, Consolas, "Courier New", monospace;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

* {
	-webkit-touch-callout: none;
	/* iOS Safari */
	-webkit-user-select: none;
	/* Safari */
	-khtml-user-select: none;
	/* Konqueror HTML */
	-moz-user-select: none;
	/* Firefox */
	-ms-user-select: none;
	/* Internet Explorer/Edge */
	user-select: none;
}

input,
select,
option {
	-webkit-touch-callout: auto;
	/* iOS Safari */
	-webkit-user-select: auto;
	/* Safari */
	-khtml-user-select: auto;
	/* Konqueror HTML */
	-moz-user-select: auto;
	/* Firefox */
	-ms-user-select: auto;
	/* Internet Explorer/Edge */
	user-select: auto;
}

input {
	border: 0;
}

h1,
h2,
h3,
h4,
h5,
h6 {
	margin: 0;
	color: rgba(0, 0, 0, 0.85);
	font-weight: 500;
}

.empty {
	flex: 1;
	width: 100%;
	height: 100%;
	position: relative;
	div {
		margin: auto;
		position: absolute;
		width: 100px;
		height: 78px;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		@include column_sb;
		img {
			width: 60px;
			margin: auto;
		}
		b {
			color: $Gary_Color;
			font-size: 12px;
			text-align: center;
		}
	}
}

.option {
	@include row_start;
	height: 100%;
	img {
		align-self: center;
		margin: 5px;
		width: 20px;
		height: 20px;
	}
}
