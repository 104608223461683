@import "@/style/common.scss";

.home {
  width: 100%;
  height: 100%;
  overflow: auto;
  .titleWrapper {
    width: 100%;
    background: #202020;
    height: 30px;
    font-weight: 500;
    .main {
      width: $maxWidth;
      @include row_sb;
      margin: auto;
      height: 100%;
    }
    .ex {
      display: flex;
      > div {
        display: flex;
        align-items: center;
        img {
          width: 21px;
          height: 13px;
          svg {
            width: 100%;
            height: 100%;
          }
        }
        span {
          font-size: 12px;
          padding: 0 rem(25px) 0 rem(6px);
        }
        b {
          color: $font_color;
          display: inline-block;
          padding-right: rem(5px);
        }
        i {
          color: $light_font_color;
          &.open {
            color: $fall;
          }
        }
      }
    }
    .userCenter {
      @include row_center;
      @include common_btn;

      img {
        width: 15px;
        height: 18px;
      }
      span {
        font-size: 14px;
        color: $white_color;
        padding-left: 5px;
      }
    }
  }
  .header {
    width: 100%;
    height: rem(66px);
    background: #151515;
    .main {
      width: $maxWidth;
      height: 100%;
      margin: auto;
      @include row_sb;
      img {
        height: 100%;
        padding: rem(10px) 0;
      }
    }
    .navBox {
      @include row_center;
      height: 100%;
      line-height: 50px;
      color: $white_color;
      font-size: 14px;
      font-weight: 500;
      .nav {
        margin-left: 30px;
        // height: 100%;
        @include row_start;
        a {
          &.active {
            color: $Active_Color;
          }

          &:hover {
            color: $Active_Color;
          }
        }
      }
    }
    .register {
      display: inline-block;
      @include common_btn;
      width: 130px;
      height: 38px;
      background: linear-gradient(90deg, #ff0002, #f78c1c);
      border-radius: 4px;
      text-align: center;
      line-height: 38px;
      color: $white_color;
      margin-left: 25px;
      font-size: 16px;
    }
  }
  .homeBanner {
    img {
      width: 100%;
    }
  }
}

.tradeNavModal {
  background-color: #252525;
  box-shadow: 1px 2px 4px 0px rgba(0, 0, 0, 0.08);
  // margin-top: 20px;
  .tradeNav {
    position: relative;
    @include column_sb;
    padding: 10px 0;
    width: 160px;

    a {
      height: 40px;
      @include row_center;
      justify-content: flex-start;
      font-size: 14px;
      padding-left: 25px;
      line-height: 40px;
      color: $white_color;
      img {
        width: 12px;
        height: 12px;
        margin-right: 8px;
      }
      svg {
        width: 12px;
        height: 12px;
        fill: $white_color;
        margin-right: 8px;
      }
      &.active {
        color: $Active_Color;
        svg {
          fill: $Active_Color;
        }
      }
      &:hover {
        color: $Active_Color;
        background: #333;
        svg {
          fill: $Active_Color;
        }
      }
    }
    .textIcon {
      width: 60px;
      height: 23px;
    }
  }
}

.hotQuoteWrapper {
  width: 100%;
  height: 726px;
  position: relative;
  background-image: url("../../assets/home/quotation_bg.png");
  background-size: contain;
  .main {
    width: $maxWidth;
    margin: auto;
    padding-top: 70px;
    @include column_start;
    h2 {
      text-align: center;
      font-size: 40px;
      font-weight: 600;
      color: #151515;
      line-height: 56px;
    }
  }
  .titleNav {
    height: 34px;
    line-height: 34px;
    @include row_center;
    @include common_btn;
    margin: 20px 0 50px;
    .item {
      font-size: 14px;
      width: 120px;
      margin: 0 10px;
      text-align: center;
      border-radius: 17px;
      color: #7b8293;
      &.active {
        background: $Active_Color;
        color: $white_color;
      }
    }
  }
  .hotList {
    @include row_sb;
    .goodsInfo {
      @include column_sb;
      position: relative;
      width: 278px;
      height: 370px;
      background: $white_color;
      border-radius: 4px;
      box-shadow: 0px 2px 15px 0px rgba(102, 102, 102, 0.15);
      color: $Black_Font_Color;
      padding: 0 40px;
      .code {
        position: absolute;
        left: 50%;
        transform: translate(-50%);
        top: -19px;
        width: 38px;
        height: 38px;
      }
      .name {
        font-size: 24px;
        text-align: center;
        margin: 48px 0 10px;
        font-weight: 700;
      }
      .contract {
        font-size: 16px;
        text-align: center;
      }
      .price {
        margin-top: 40px;
        @include row_center;

        div {
          align-self: center;
          position: relative;

          b {
            font-size: 48px;
          }

          .updown {
            justify-content: flex-start;
            position: absolute;
            right: -20px;
            top: 8px;
          }
        }
      }

      .rate {
        @include row_sb;
        font-size: 22px;
        padding: 15px 10px 30px;

        &.rest {
          font-size: 14px;
          @include row_center;
        }
      }

      .goTrade {
        border-top: 1px solid #eaecef;

        a {
          display: block;
          width: 151px;
          height: 44px;
          background: linear-gradient(90deg, #ff0002, #f78c1c);
          border-radius: 5px;
          margin: 30px auto;
          color: $white_color;
          font-size: 14px;
          text-align: center;
          line-height: 44px;
        }

        &.rest {
          a {
            background: #f5f5f5;
            color: $Gary_Color;
            cursor: not-allowed;
          }
        }
      }
    }
  }
  .more {
    @include row_end;
    padding-top: 54px;
    a {
      font-size: 16px;
      font-weight: bolder;
      color: $raise;
    }
  }
}

.introWrapper {
  width: 100%;
  .intro1,
  .intro2 {
    position: relative;
    padding-top: 80px;
    height: 774px;
    background-size: 115%;
    .terminal {
      position: relative;
      z-index: 1;
      display: block;
      width: $maxWidth;
      margin: auto;
    }
  }
  .intro1 {
    background: url("../../assets/home/section1_bg.png") no-repeat center;
    background-size: cover;
  }
  .intro2 {
    background: url("../../assets/home/section2_bg.png") no-repeat center;
    background-size: cover;
    height: 889px;
    padding-top: 110px;
  }
  .download {
    width: 100%;
    height: 717px;
    background: linear-gradient(45deg, #ff0002, #f78c1c);
    .content {
      width: $maxWidth;
      display: flex;
      justify-content: center;
      margin: auto;
      padding-top: 110px;
      .terminal {
        width: 650px;
        height: 100%;
      }
      .info {
        margin-left: 160px;
        h5 {
          font-size: 48px;
          font-weight: 600;
          color: $white_color;
          letter-spacing: 1px;
          padding-top: 100px;
        }
        p {
          width: 450px;
          font-size: 14px;
          color: $white_color;
          line-height: 26px;
          padding: 20px 0;
        }
        div {
          display: flex;

          > img {
            width: 160px;
            height: 48px;
            margin: 0 20px 20px 0;
            cursor: pointer;
          }

          .qrCode {
            width: 48px;
            height: 48px;
          }
        }
      }
    }
  }
}
.homeQR {
  width: 150px;
  height: 150px;
  background: $white_color;
  margin-top: 4px;
  img {
    width: 100%;
    height: 100%;
  }
}
