@import "@/style/common.scss";

.tradeWrapper {
	@include column_sb;
	background: $Black_Bg;
	width: 100%;
	display: block;
	height: 100%;
	overflow: auto;
	.tradeHeader {
		background: $Deep_Section_Bg;
		display: flex;
		justify-content: space-between;
		margin: 10px;
		height: 58px;
		padding: 0 10px;
		.left {
			display: flex;
			img {
				width: 20px;
				height: 20px;
				align-self: center;
			}
			.ant-carousel {
				align-self: center;
			}
			.notice_box {
				width: 272px;
				align-self: center;
				color: #c0c2cb;
				margin: 0 12px;
				p {
					line-height: 58px;
					text-overflow: ellipsis;
					white-space: nowrap;
					overflow: hidden;
				}
			}
			a {
				align-self: center;
				color: $Gary_Color;
				font-size: 14px;
				padding-left: 15px;
			}

			.more {
				color: #f78c1c;
				font-size: 14px;
				font-family: PingFangSC, PingFangSC-Medium;
				font-weight: 500;
				display: flex;
				align-items: center;
				gap: 6px;

				&::after {
					content: "";
					display: inline-block;
					width: 6px;
					height: 6px;
					border-style: solid;
					border-width: 1px 1px 0 0;
					border-color: #f78c1c;
					border-bottom-left-radius: 2px;
					transform: rotate(45deg);
				}
			}
		}
		.right {
			display: flex;
			padding: 10px 0;
			> div {
				@include column_sb;
				margin-right: 30px;
				label {
					color: $Gary_Color;
					font-size: 12px;
					display: flex;
					div {
						font-size: 10px;
						background: $Active_Color;
						color: $Deep_Gary_Color;
						margin-left: 10px;
						height: 12px;
						line-height: 12px;
						padding: 0 5px;
						border-radius: 1px;
						@include common_btn;
					}
				}
				b {
					font-size: 16px;
					color: $white_color;
					font-weight: normal;
				}
				&.switchBtn {
					align-self: center;
					margin-left: 20px;
					display: flex;
					flex-direction: row;
					border-radius: 3px;
					overflow: hidden;
					div {
						height: 28px;
						line-height: 28px;
						width: 65px;
						text-align: center;
						font-size: 12px;
						color: $Gary_Color;
						border: 1px solid $Gary_Color;
						@include common_btn;
						&:last-child {
							border-left: none;
						}
						&.active {
							background: $Active_Color;
							color: $Black_Font_Color;
							border-color: $Active_Color;
						}
					}
				}
			}
		}
	}
	.tradeMain {
		flex: 1;
		padding: 0 10px 10px;
		display: flex;
		justify-content: space-between;
		.tradeInfo {
			flex: 1;
			@include column_sb;
			.tradeTop {
				display: flex;
				height: 600px;
			}
		}
	}
}
.quotes {
	width: 300px;
	height: 100%;
	margin-right: 10px;
	@include column_sb;
	.quotesInfo {
		background: $Deep_Section_Bg;
		height: 100%;
		.quotesNav {
			height: 40px;
			line-height: 40px;
			font-size: 14px;
			> div {
				padding: 0 6px;
				span {
					@include common_btn;
					color: $white_color;
					&.active {
						color: $Active_Color;
					}
				}
			}
		}
	}
	.quoteBox {
		position: relative;
		z-index: 10;
		&.foreignBox {
			.header div {
				flex: 0.3;
				&:last-child {
					flex: 0.3;
				}
			}
			.list .item .leftInfo {
				flex: 1;
			}
		}
		.header {
			padding: 0 5px;
			display: flex;
			font-size: 12px;
			height: 30px;
			line-height: 30px;
			color: $Gary_Color;
			justify-content: space-between;
			div {
				flex: 0.25;
				@include row_end;
				align-items: initial;
				position: relative;
				&:first-child {
					flex: 0.4;
					justify-content: flex-start;
				}
				&:last-child {
					flex: 0.075;
				}
			}
			.middle {
				b {
					@include common_btn;
				}
			}
			.sort {
				width: 10px;
				height: 12px;
				display: inline-flex;
				flex-direction: column;
				justify-content: center;
				cursor: pointer;
				margin-top: 10px;
				z-index: 10;
				margin-left: 2px;
				i {
					height: 5px;
				}
				.up {
					margin-bottom: 1px;
					@include triangle(top, 4px, 4px, $Gary_Color);
					&.active {
						border-bottom-color: $Active_Color;
					}
				}

				.down {
					margin-top: 1px;
					@include triangle(bottom, 4px, 4px, $Gary_Color);
					&.active {
						border-top-color: $Active_Color;
					}
				}
			}
		}
		.list {
			height: 530px;
			overflow-y: auto;
			.item {
				display: flex;
				padding: 0 5px;
				color: $white_color;
				height: 44px;
				line-height: 44px;
				@include common_btn;
				&:hover {
					background: #252525;
				}
				.leftInfo {
					display: flex;
					flex: 0.9;
					> div {
						flex: 0.3;
						display: flex;
						justify-content: flex-end;
						font-size: 12px;
						&.name {
							flex: 0.4;
							justify-content: flex-start;
							font-size: rem(14px);
							img {
								align-self: center;
								width: 18px;
								height: 18px;
								margin-right: 5px;
							}
						}
					}
				}
				.favor {
					flex: 0.1;
					display: flex;
					justify-content: flex-end;
					img {
						align-self: center;
						width: 16px;
						height: 16px;
					}
				}
			}
			&.crypto {
				.item {
					.leftInfo {
						.name {
							font-size: 12px;
						}
					}
				}
			}
		}
	}
}
.chart {
	flex: 1;
	background: $Deep_Section_Bg;
	@include column_sb;
	.chartInfo {
		height: 50px;
		padding: 12px 16px 12px;
		@include column_sb;
		.info {
			display: flex;
			color: $white_color;
			user-select: none;
			> div {
				align-self: center;
				padding-right: 10px;
				display: flex;
				justify-content: flex-start;
				img {
					width: 24px;
					height: 24px;
					margin-right: 5px;
					align-self: center;
				}
				&:last-child {
					padding: 0;
				}
				span {
					display: inline-block;
					margin-right: 10px;
					align-self: center;
				}
				b {
					font-size: rem(30px);
					line-height: rem(40px);
				}
				label {
					font-size: 12px;
					text-align: center;
					color: $Gary_Color;
					margin-bottom: 5px;
				}
				div {
					font-size: 14px;
					text-align: center;
				}
				&:first-child {
					font-size: 16px;
				}
				&.grid {
					@include column_sb;
				}
			}
			.infoName {
				display: flex;
				justify-content: space-between;
			}
		}
	}
}
.position {
	margin-top: 10px;
	flex: 1;
	width: 100%;

	@include column_start;
	max-height: 573px;
	.positionHead {
		display: flex;
		justify-content: space-between;
		height: 46px;
		background: #151515;
		.nav {
			display: flex;
			justify-content: flex-start;
			line-height: 46px;
			color: $Gary_Color;
			@include common_btn;
			div {
				width: 150px;
				text-align: center;
				font-size: 14px;
				&.active {
					@include active_btn($Deep_Section_Bg, $Active_Color, $white_color);
				}
			}
		}
		.sellAll {
			padding-right: 15px;
			display: flex;
			.profit {
				align-self: center;
				label {
					color: $white_color;
					font-size: 14px;
				}
				b {
					font-size: 24px;
					display: inline-block;
					padding: 0 7px 0 15px;
				}
				i {
					color: $Gary_Color;
					font-size: 12px;
				}
			}
			.sell {
				@include common_btn;
				align-self: center;
				width: 120px;
				height: 28px;
				line-height: 28px;
				font-size: 14px;
				text-align: center;
				margin-left: 30px;
				color: $Deep_Gary_Color;
				background: $Active_Color;
				border-radius: 2px;
				border: 1px solid $Active_Color;
				&.emptyBtn {
					background: transparent;
					color: #c0c2cb;
					border-color: #535353;
				}
			}
		}
	}
	.positionList {
		height: 527px;
		word-break: keep-all;
		display: flex;
		flex-direction: column;
		// overflow: auto;
		.listBox {
			flex: 1;
			overflow: auto;
			// height: 100%;
			.empty div {
				width: 150px;
				height: 100px;
				img {
					width: 100px;
				}
				b {
					margin-top: 10px;
				}
			}
		}
		.header,
		.item {
			@include row_sb;
			height: 46px;
			line-height: 46px;
			user-select: none;
			background: $Deep_Section_Bg;
			font-size: 12px;
			color: $Gary_Color;
			padding-left: 20px;
			width: 100%;
			border-bottom: 1px solid $Black_Font_Color;
			position: relative;
			i {
				position: absolute;
				border: 6px solid transparent;
				width: 0;
				height: 0;
				top: 0;
				left: 0;
				&.redB {
					border-top-color: $raise;
					border-left-color: $raise;
				}
				&.greenB {
					border-top-color: $fall;
					border-left-color: $fall;
				}
			}
			> div {
				width: 5.5%;
				text-align: right;
				white-space: nowrap;
				word-break: keep-all;
				&:nth-child(2) {
					justify-content: flex-start;
				}
				&:last-child {
					position: sticky;
					right: 0;
				}
			}
			.positionShort {
				width: 10%;
			}
			.positionLong,
			.pendingLong,
			.settleLong {
				width: 10%;
				display: flex;
				justify-content: flex-end;
			}
			.positionLonger {
				width: 15%;
				display: flex;
				justify-content: center;
				div {
					height: 26px;
					line-height: 24px;
					text-align: center;
					align-self: center;
					border-radius: 2px;
					padding: 0 10px;
					margin: 0 8px;
					font-size: 12px;
					border: 1px solid $Active_Color;
					color: $Active_Color;
					//margin: 0 10px;
					@include common_btn;
					&:hover {
						background: $Active_Color;
						color: $Deep_Gary_Color;
					}
					&.alreadySell {
						border-color: $font_color;
						color: $font_color;
						&:hover {
							background: none;
							color: $font_color;
						}
					}
				}
			}
			.id {
				@include row_end;
				p {
					width: 80%;
					text-overflow: ellipsis;
					overflow: hidden;
					word-break: break-all;
				}
				.commonCopy {
					width: 14px;
					margin-left: 5px;
				}
			}
			.pendingLonger {
				height: 100%;
				width: 10%;
				text-align: center;
				padding: 0 10px;
				@include column_sb;
				justify-content: center;
				div {
					height: 30px;
					line-height: 28px;
					border-radius: 2px;
					border: 1px solid $Gary_Color;
					color: $Gary_Color;
					padding: 0 10px;
					&:hover {
						color: $Active_Color;
						border-color: $Active_Color;
					}
				}
			}
			.settleShort {
				width: 10%;
			}
			.settleLong {
				&.id {
					justify-content: center;
				}
			}
		}
		.item {
			height: 58px;
			line-height: 58px;
			background: $Black_Font_Color;
			color: $white_color;
			cursor: pointer;
			&:hover {
				background: $Deep_Section_Bg;
			}
		}
		&.settleList {
			.id {
				padding-right: 10px;
			}
		}
		.btnBox {
			display: flex;
			justify-content: space-between;
			padding: 20px 0;
			div {
				width: 150px;
				height: 40px;
				text-align: center;
				line-height: 40px;
				border-radius: 3px;
				background: #282b33;
				color: $Gary_Color;
				@include common_btn;
				&.confirm {
					color: $white_color;
					background: $Active_Color;
				}
			}
		}
	}
}

.empty {
	flex: 1;
	width: 100%;
	height: 100%;
	position: relative;
	div {
		margin: auto;
		position: absolute;
		width: 100px;
		height: 78px;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		@include column_sb;
		img {
			width: 60px;
			margin: auto;
		}
		b {
			color: $Gary_Color;
			font-size: 12px;
			text-align: center;
		}
	}
}
.tradeOrder,
.tradeLeverOrder {
	width: 354px;
	@include column_start;
	.orderBox {
		@include column_start;
		margin-left: 10px;
		position: relative;
		.navBox {
			@include row_sb;
			height: 44px;
			line-height: 44px;
			background: #151515;
			> div {
				display: flex;
			}
			.title {
				width: 100%;

				> div {
					flex: 1;
					color: $white_color;
					text-align: center;
					@include common_btn;
					font-size: 14px;

					&.active {
						border-top: 2px solid $Active_Color;
						background: #202020;
					}
				}
			}
		}
	}
	.orderInfo {
		@include column_sb;
		.dynamic {
			padding: 16px 16px 20px;
			background: $Deep_Section_Bg;
			margin-bottom: 1px;
			font-weight: 500;
			.dynamicInfo {
				display: flex;
				> div {
					display: flex;
					flex: 1;
					justify-content: space-between;
					color: $Gary_Color;
					font-size: 12px;

					b {
						margin-left: 5px;
					}

					&:first-child {
						padding-right: 8px;

						b {
							color: $raise;
						}
					}

					&:last-child {
						padding-left: 8px;

						b {
							color: $fall;
						}
					}
				}
			}
			.volumeInfo {
				position: relative;
				margin-top: 8px;
				div {
					position: absolute;
					height: 5px;
					&.buy {
						left: 0;
						background: $raise;
					}
					&.sell {
						right: 0;
						background: $fall;
					}
				}
			}
		}
		.line {
			display: flex;
			justify-content: space-between;
			padding: 12px 16px;
			margin-bottom: 1px;
			background: $Deep_Section_Bg;
			label {
				font-size: 14px;
				align-self: center;
				color: $Gary_Color;
				display: flex;

				img {
					align-self: center;
					width: 14px;
					height: 14px;
					margin-left: 5px;
				}
			}
			.volume {
				display: flex;

				> div {
					font-size: rem(14px);
				}

				.deduct {
					color: $white_color;
					font-size: 12px;
					align-self: center;
					margin-right: 5px;
				}

				// .ant-switch-checked {
				// 	background: $Active_Color;
				// }

				.inputBox {
					display: flex;
					justify-content: space-between;
					border: 1px solid $Gary_Color;
					border-radius: 3px;
					height: 32px;
					padding: 0 5px;
					width: 202px;
					input {
						background: transparent;
						border: none;
						box-shadow: none;
						outline: none;
						font-size: 14px;
						width: 100px;
						color: $Active_Color;
					}

					.ctrlBox {
						display: flex;

						.btn {
							margin-left: 10px;
							width: 20px;
							height: 20px;
							align-self: center;
						}
					}

					&:hover {
						border-color: $Active_Color;
					}

					&.focus {
						border-color: $Active_Color;
					}
				}
			}
			&.one {
				.volume {
					div {
						width: 65px;
						height: 24px;
						line-height: 22px;
						text-align: center;
						border: 1px solid $Gary_Color;
						color: $Gary_Color;
						margin-left: 15px;
						border-radius: 2px;
						@include common_btn;

						&.active {
							border-color: $Active_Color;
							color: $Active_Color;
						}
					}
				}
			}

			&.two {
				@include column_sb;

				label {
					align-self: flex-start;
				}

				.volume {
					margin-top: 10px;
					display: flex;
					justify-content: space-between;

					div {
						flex: 0.16;
						height: 24px;
						line-height: 22px;
						text-align: center;
						border: 1px solid $Gary_Color;
						margin-right: 10px;
						color: #c0c2cb;
						border-radius: 2px;
						@include common_btn;

						&.active {
							color: $Active_Color;
							border-color: $Active_Color;
						}

						&:last-child {
							margin: 0;
						}
					}

					&.stopLoss {
						div {
							flex: 0.24;
						}
					}
				}
			}

			&:first-child {
				padding-top: 22px;
			}

			.stopProfit {
				color: #c0c2cb;
			}
		}
		.total {
			background: $Deep_Section_Bg;
			margin-bottom: 1px;
			> div {
				display: flex;
				justify-content: space-between;
				font-size: 12px;
				padding: 10px 16px;

				label {
					color: $Gary_Color;
					&.total_price {
						color: #c0c2cb;
					}
				}

				div {
					color: $Gary_Color;
				}

				&:last-child {
					font-size: 14px;

					div {
						font-size: 20px;
						color: $Active_Color;
					}
				}
			}
		}
		.btnBox {
			margin-top: 1px;
			display: flex;
			justify-content: space-between;

			.btn {
				width: 45%;
				height: 50px;
				font-size: 14px;
				color: $white_color;
				@include common_btn;
				@include column_sb;
				position: relative;
				text-align: center;
				justify-content: center;

				b {
					margin-top: 5px;
				}
				&.redBG {
					background: $raise;
				}
				&.greenBG {
					background: $fall;
				}

				&:first-child {
					border-radius: 2px 0 0 2px;

					&::before {
						position: absolute;
						right: -24px;
						top: 0;
						content: "";
						width: 0;
						height: 0;
						border-top: 25px solid $raise;
						border-right: 12px solid transparent;
						border-bottom: 25px solid transparent;
						border-left: 12px solid $raise;
						border-top-right-radius: 3px;
						z-index: 1;
					}
				}

				&:last-child {
					border-radius: 0 3px 3px 0;

					&::before {
						position: absolute;
						left: -24px;
						top: 0;
						content: "";
						width: 0;
						height: 0;
						border-top: 25px solid transparent;
						border-right: 12px solid $fall;
						border-bottom: 25px solid $fall;
						border-left: 12px solid transparent;
						border-bottom-left-radius: 3px;
						z-index: 1;
					}
				}
			}
		}
	}
	.orderCover {
		position: absolute;
		top: 0;
		left: 0;
		background: $Deep_Section_Bg;
		width: 100%;
		height: 100%;
		z-index: 10;
		div {
			margin: auto;
			width: 100%;
			height: 100%;
			@include column_sb;
			justify-content: center;
			img {
				align-self: center;
				width: 70px;
				height: 70px;
				margin: 20px auto;
			}
			p {
				color: $white_color;
				text-align: center;
				font-size: 14px;
				height: 20px;
				line-height: 20px;
			}

			a {
				width: 150px;
				height: 34px;
				border: 1px solid $Active_Color;
				font-size: 13px;
				text-align: center;
				color: $Active_Color;
				align-self: center;
				line-height: 32px;
				margin: 20px auto;
				border-radius: 3px;
				&:last-child {
					margin: 0 auto;
					background: $Active_Color;
					color: $Deep_Gary_Color;
				}
			}
		}
	}
}

.tradeLeverOrder {
	width: 354px;
	@include column_start;
	.leverBox {
		background: $Deep_Section_Bg;
		padding: 12px 16px;
		@include column_sb;
		.leverTitle {
			display: flex;
			justify-content: space-between;
			font-size: 12px;
			color: $Gary_Color;

			b {
				color: $Active_Color;
			}

			> div {
				@include common_btn;
				display: flex;

				i {
					align-self: center;
				}

				svg {
					width: 13px;
					height: 13px;
					fill: $Gary_Color;
					margin-left: 3px;
				}

				&:hover {
					color: $Active_Color;
					svg {
						fill: $Active_Color;
					}
				}
			}
		}
	}
	.profitBox {
		display: flex;
		justify-content: space-between;
		padding: 12px 16px;
		background: $Deep_Section_Bg;
		margin-top: 1px;
		font-size: 12px;
		color: $Gary_Color;

		label {
			flex: 0.2;
			height: 24px;
			line-height: 24px;
			margin-top: 3px;
		}

		> div {
			flex: 0.8;
			.leverInputBox {
				flex: 1;
				display: flex;
				align-items: center;
				position: relative;
				border: 1px solid #858585;
				border-radius: 2px;
				height: 30px;
				input {
					width: 90%;
					background: transparent;
					border: none;
					outline: none;
					height: 28px;
					line-height: 28px;
					font-size: 14px;
					border-radius: 2px;
					color: $white_color;
					padding: 0 6px;
				}
				.unit {
					position: absolute;
					right: 6px;
				}
				.ctrlBox {
					position: absolute;
					right: 6px;
					display: flex;
					.btn {
						margin-left: 6px;
						width: 20px;
						height: 20px;
						align-self: center;
					}
				}
				&:hover {
					border-color: $Active_Color;
				}

				&.focus {
					border-color: $Active_Color;
				}
			}
			// .label-input{
			//   input{
			//     color: $Active_Color;
			//   }
			// }
			.marginBox {
				display: flex;
				margin-top: 8px;
				justify-content: space-between;

				div {
					border: 1px solid $Gary_Color;
					width: 50px;
					height: 24px;
					line-height: 22px;
					border-radius: 2px;
					text-align: center;
					color: #c0c2cb;
					@include common_btn;

					&.active {
						border-color: $Active_Color;
						color: $Active_Color;
					}

					&:hover {
						border-color: $Active_Color;
						color: $Active_Color;
					}
				}
			}
		}
		.emulate-input {
			.controller {
				right: 0;
			}
		}
	}
	.chooseBox {
		display: flex;
		@include column_sb;
		padding: 12px 16px;
		background: $Deep_Section_Bg;
		margin-top: 1px;
		font-size: 12px;
		label {
			color: $Gary_Color;
		}
		.proportion {
			display: flex;
			margin-top: 8px;
			justify-content: space-between;

			div {
				border: 1px solid $Gary_Color;
				width: 50px;
				height: 24px;
				line-height: 22px;
				border-radius: 2px;
				text-align: center;
				color: #c0c2cb;
				@include common_btn;

				&:hover {
					border-color: $Active_Color;
					color: $Active_Color;
				}

				&.active {
					border-color: $Active_Color;
					color: $Active_Color;
				}
			}
		}
	}
	.orderCover {
		position: absolute;
		top: 0;
		left: 0;
		background: $Deep_Section_Bg;
		width: 100%;
		height: 100%;
		z-index: 10;
		div {
			margin: auto;
			width: 100%;
			height: 100%;
			@include column_sb;
			justify-content: center;
			img {
				align-self: center;
				width: 70px;
				height: 70px;
				margin: 20px auto;
			}
			p {
				color: $white_color;
				text-align: center;
				font-size: 14px;
				height: 20px;
				line-height: 20px;
			}

			a {
				width: 150px;
				height: 34px;
				border: 1px solid $Active_Color;
				font-size: 13px;
				text-align: center;
				color: $Active_Color;
				align-self: center;
				line-height: 32px;
				margin: 20px auto;
				border-radius: 3px;
				&:last-child {
					margin: 0 auto;
					background: $Active_Color;
					color: $Deep_Gary_Color;
				}
			}
		}
	}
}
.tradeRule {
	padding: 0 10px;
	flex: 1;
	max-height: rem(750px);
	overflow-y: auto;
	flex: 1;
	background: $Deep_Section_Bg;
	margin-top: 10px;
	margin-left: 10px;
	&.short {
		max-height: 513px;
	}

	&.high {
		max-height: 573px;
	}
	h5 {
		height: rem(50px);
		line-height: rem(50px);
		font-size: 14px;
		text-align: center;
		color: #edeef2;
		border-top: 1px solid $Line_Color;
		border-bottom: 1px solid $Line_Color;
	}
	div {
		display: flex;
		padding: rem(5px);
		line-height: rem(30px);
		justify-content: space-between;
		font-size: 12px;
		border-bottom: 1px solid $Line_Color;
		color: $white_color;
		label {
			color: $Gary_Color;
		}
		.search {
			width: 200px;
			overflow: hidden;
			text-overflow: ellipsis;
			white-space: nowrap;
			text-align: right;
			//display: flex;
			//justify-content: flex-start;
		}
		&.production {
			@include column_sb;
			padding: 5px 0 15px;
			b {
				height: 37px;
				text-align: center;
				line-height: 37px;
				color: $Active_Color;
				font-size: 14px;
			}
			span {
				line-height: 24px;
				font-size: 14px;
				color: $Gary_Color;
				text-indent: 26px;
			}
		}
	}
}
.hint {
	color: $Active_Color;
	font-size: 12px;
	background-color: #000;
	padding: 12px 6px;
	margin-left: 6px;
	p {
		margin-bottom: 6px;
	}
}

.settingModal,
.spslModal,
.sellModal,
.orderModal {
	width: 400px;
	z-index: 10002;
	position: relative;
	left: 50%;
	top: 50%;
	transform: translate(-50%, -50%);
	animation: flyIn 0.2s ease-in-out;
	animation-fill-mode: forwards;

	@keyframes flyIn {
		0% {
			opacity: 0;
			scale: 0;
		}
		100% {
			opacity: 1;
			scale: 1;
		}
	}
	.close {
		position: absolute;
		top: 0;
		right: 0;
		width: 50px;
		height: 50px;
		@include row_center;
		svg {
			width: 16px;
			height: 16px;
			fill: #fff;
			@include common_btn;
		}
	}
	.header {
		padding: 16px 24px;
		color: rgba(0, 0, 0, 0.65);
		background: #1b1d26;
		@include row_center;
		.title {
			font-size: 18px;
			color: $white_color;
		}
	}
	.content {
		padding: 0;
		background: #121319;
		font-size: 14px;
	}
	.setTitle {
		width: 100%;
		color: $Gary_Color;
		font-size: 12px;
		padding: 15px;
		border-bottom: 1px solid rgba(255, 255, 255, 0.05);
		display: flex;
		justify-content: space-between;
		span {
			align-self: center;
		}
		b {
			font-size: 20px;
			color: $white_color;
		}
	}
	.setBox {
		@include column_sb;
		padding: 15px;
		border-bottom: 1px solid rgba(255, 255, 255, 0.05);
		.sliderTop {
			display: flex;
			justify-content: space-between;
			> div {
				display: flex;
				@include common_btn;

				img {
					align-self: center;
				}
			}
		}
		.sliderBottom {
			display: flex;
			justify-content: space-between;
			div {
				color: $white_color;
			}
		}
	}
	.setLine {
		padding: 15px;
		@include row_sb;
		border-bottom: 1px solid rgba(255, 255, 255, 0.05);

		label {
			color: $white_color;
			font-size: 12px;
			display: flex;
			img {
				align-self: center;
				margin-left: 4px;
				width: 14px;
				height: 14px;
				margin-top: -2px;
			}
		}
	}
	.setBtn {
		@include row_sb;
		padding: 15px;

		div {
			@include common_btn;
			width: 45%;
			height: 40px;
			line-height: 40px;
			text-align: center;
			background: #282b33;
			color: $white_color;
			border-radius: 3px;

			&:last-child {
				background: $Active_Color;
				color: $Deep_Gary_Color;
			}
		}
	}
}
.spslModal {
	width: 480px;
	background: #121319;
	font-size: 14px;
	.oneLine {
		@include row_sb;
		height: 50px;
		border-bottom: 1px solid rgba(255, 255, 255, 0.05);
		padding: 0 15px;
		color: $white_color;
		line-height: 50px;
		div {
			display: flex;
			img {
				align-self: center;
				width: 22px;
				height: 22px;
				margin-right: 5px;
			}
		}
		b {
			font-size: 18px;
			span {
				color: $white_color;
				font-size: 14px;
			}
		}
		&.switchBtn {
			justify-content: center;
			div {
				width: 120px;
				justify-content: center;
				@include common_btn;
			}
			.active {
				color: $Active_Color;
				border-bottom: 1px solid $Active_Color;
			}
		}
	}
	.twoLine {
		padding: 10px 15px;
		border-bottom: 1px solid rgba(255, 255, 255, 0.05);
		@include column_sb;
		label {
			color: $white_color;
		}
		.inputBox {
			margin: 8px 0;
			position: relative;
			border: 1px solid #858585;
			border-radius: 2px;
			flex: 1;
			height: 30px;
			input {
				flex: 1;
				padding-left: 6px;
				height: 34px;
				line-height: 34px;
				font-size: 14px;
				background: transparent;
				border: none;
				box-shadow: none;
				outline: none;
				width: 90%;
				color: $Active_Color;
			}
			.controller {
				@include row_sb;
				position: absolute;
				width: 46px;
				right: 6px;
				height: 34px;
			}
			&:hover {
				border-color: $Active_Color;
			}

			&.focus {
				border-color: $Active_Color;
			}
		}
		> div {
			display: flex;
			justify-content: space-between;
			span {
				color: $white_color;
			}
			b {
				color: $Active_Color;
			}
		}
	}
	.sliderBox {
		@include column_sb;
		padding: 24px;
		border-bottom: 1px solid rgba(255, 255, 255, 0.05);
		.sliderTitle {
			@include row_sb;
			padding-bottom: 20px;
			color: $white_color;
		}
		.controller {
			@include row_sb;
			.btn {
				@include common_btn;
			}
		}
	}
	.btnBox {
		@include row_sb;
		padding: 20px 0;
		margin: 0 15px;
		div {
			width: 200px;
			height: 40px;
			text-align: center;
			line-height: 40px;
			border-radius: 3px;
			background: #282b33;
			color: #858585;
			@include common_btn;
		}
		.confirm {
			color: #fff;
			background: #f78c1c;
		}
	}
}
.sellModal {
	background: #121319;
	position: relative;
	height: 200px;
	@include column_sb;
	font-size: 14px;
	padding: 0 24px;
	.confirmInfo {
		@include column_end;
		justify-content: flex-end;
		color: $Active_Color;
		text-align: center;
		padding: rem(40px) 0;
	}
	.btnBox {
		@include row_sb;
		padding: 20px 0;
		div {
			width: 150px;
			height: 40px;
			text-align: center;
			line-height: 40px;
			border-radius: 3px;
			background: #282b33;
			color: #858585;
			@include common_btn;
		}
		.confirm {
			color: #fff;
			background: #f78c1c;
		}
	}
}
.orderModal {
	background: #121319;
	position: relative;
	// font-size: 14px;
	.header {
		background: #1b1d26;
		border: none;
		justify-content: flex-start;
	}
	.content {
		padding: 10px 10px 20px;
		h2 {
			font-size: 18px;
			padding: 1em 0;
			// width: 100%;
			text-align: center;
			color: #fff;
		}
		> div {
			display: flex;
			justify-content: space-between;
			color: $white_color;
			height: 34px;
			line-height: 34px;
			padding: 0 25px;
			border-bottom: 1px solid rgba(255, 255, 255, 0.05);

			label {
				font-size: 12px;
				color: #c0c2cb;
			}

			b {
				font-size: 12px;
				font-weight: bold;

				&.total {
					font-size: 14px;
					color: $Active_Color;
					font-weight: bolder;
				}
			}

			&.btnBox {
				border: none;
				height: auto;
				margin-top: 15px;

				div {
					width: 45%;
					text-align: center;
					background: #282b33;
					color: #c0c2cb;
					border-radius: 2px;
					@include common_btn;

					&:last-child {
						background: $Active_Color;
						color: $Black_Font_Color;
					}
				}
			}
		}
	}
}
