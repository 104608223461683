@import "@/style/common.scss";

.activityWrapper {
	@include column_sb;
	background: #151515;
	height: 100%;
	display: block;
	overflow: auto;
	.bannerBox {
		position: relative;
		margin-bottom: 100px;
		.bg {
			width: 100%;
		}
	}
	main {
		flex: 1;
		width: $maxWidth;
		min-height: 700px;
		margin: 40px auto 80px;
		text-align: center;
		> div.block1,
		> div.block2 {
			position: relative;
			padding: 80px;
			background: #fffdf1;
			border-radius: 50px;
			width: 1182px;

			> img {
				position: absolute;
				left: -20px;
				top: 60px;
			}
			> div {
				@include row_sb;
				margin-top: 50px;
				div.redBox {
					width: 100%;
					height: 244px;
					line-height: 130px;
					color: #e43a37;
					font-size: 66px;
					text-align: center;
					background: url(../../assets/activity/redBox_bg.png) center center no-repeat;
					span {
						font-size: 22px;
					}
				}
				p {
					color: #e43a37;
					font-size: 28px;
					margin: 20px 0 15px;
					div {
						@include column_start;
						align-items: center;
					}
					em {
						font-style: normal;
						font-size: 18px;
						line-height: 18px;
					}
				}
				span {
					display: inline-block;
					width: 300px;
					height: 253px;
					margin: 0 60px;
					font-size: 24px;
					width: 164px;
					height: 42px;
					line-height: 42px;
					border-radius: 6px;

					&.linkBtn {
						cursor: pointer;
						color: #333;
						background: linear-gradient(to top, #fee49c, #ffc50a);
					}
					&.grayBtn {
						color: #fff;
						background: #ccc;
					}
				}

				.littlePerson {
					width: 200px;
					height: 200px;
					background: url(../../assets/activity/littleperson.svg) center center no-repeat;
					background-size: 600%;
					margin-left: -150px;
				}
			}
		}
		> div.block1 {
			height: 626px;
			margin-top: 40px;
		}
		> div.block2 {
			margin-top: 40px;
			margin-bottom: 100px;
		}
		> div.block3 {
			display: flex;
			justify-content: space-around;
			margin-top: 60px;
			img {
				width: 521px;
			}
		}
	}
}
