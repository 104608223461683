@import "@/style/common.scss";

.proxyWrapper {
	@include column_start;
	background: #fafafa;
	header {
		background: rgba(0, 0, 0, 0.3);
		position: absolute;
		width: 100%;
	}
	.bannerBox {
		height: 386px;
		width: 100%;
		position: relative;
		margin-bottom: 32px;
		.img1 {
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			z-index: 0;
		}
		.img2 {
			font-size: 0;
			position: absolute;
			width: 100%;
			height: 443px;
		}
		.titleBox {
			@include column_center;
			position: absolute;
			z-index: 1;
			margin: auto;
			width: $maxWidth;
			height: 100%;
			left: 0;
			right: 0;
			top: 0;
			bottom: 0;
			margin: auto;
			div {
				margin-top: 43px;
			}
			.title {
				text-align: center;
				height: 67px;
				font-size: 48px;
				font-weight: 600;
				color: $white_color;
				line-height: 67px;
			}
			a {
				align-self: center;
				width: 200px;
				height: 56px;
				background: #f78c1c;
				text-align: center;
				line-height: 56px;
				border-radius: 3px;
				color: $white_color;
				margin-top: 40px;
				font-weight: 500;
				@include common_btn;
			}
		}
		.titleBox2 {
			margin-top: 42px;
			position: relative;
			z-index: 10;
			h5 {
				text-align: center;
				height: 45px;
				font-size: 32px;
				font-weight: 400;
				color: $white_color;
				line-height: 45px;
			}
			p {
				height: 20px;
				font-size: 14px;
				font-weight: 400;
				text-align: center;
				color: #dddddd;
				line-height: 20px;
			}
		}
		.stepBox {
			width: $maxWidth;
			margin: auto;
			padding: 40px 0 60px;
			@include column_center;
			.step {
				position: relative;
				z-index: 10;
				@include row_center;
				> div {
					display: flex;
					flex-direction: column;
					&:nth-child(2) {
						margin: 0 200px;
					}
					img {
						width: 50px;
						height: 50px;
						margin: 0 auto 20px;
					}
					b {
						height: 20px;
						font-size: 14px;
						font-weight: 400;
						text-align: center;
						color: #dddddd;
						line-height: 20px;
					}
				}
			}
			.btn {
				position: relative;
				margin: auto;
				z-index: 10;
				width: 500px;
				height: 56px;
				margin-top: 40px;
				background: $Active_Color;
				border-radius: 4px;
				line-height: 56px;
				text-align: center;
				color: $white_color;
				font-weight: 500;
				@include common_btn;
			}
			.checkList {
				position: relative;
				margin: auto;
				z-index: 10;
				width: 300px;
				height: 40px;
				line-height: 40px;
				text-align: center;
				border: 1px solid $Active_Color;
				color: $Active_Color;
				margin-top: 25px;
				font-weight: 500;
			}
		}
		&.box2 {
			height: 443px;
		}
	}
	.section {
		width: $maxWidth;
		margin: auto;
		padding: 40px;
		background: $white_color;
		margin-bottom: 32px;
		@include boxShadow;
		h5 {
			height: 45px;
			font-size: 32px;
			font-weight: 600;
			text-align: center;
			color: $Black_Font_Color;
			line-height: 45px;
		}
		p {
			height: 20px;
			font-size: 14px;
			font-weight: 500;
			text-align: center;
			color: $Gary_Color;
			line-height: 20px;
			margin: 10px auto 30px;
		}
		img {
			display: block;
			margin: auto;
		}
	}
	.connect {
		position: relative;
		margin-bottom: 50px;
		height: 257px;
		img {
			position: absolute;
			height: 257px;
		}
		.info {
			@include column_center;
			position: relative;
			z-index: 10;
			padding-top: 60px;
			h5 {
				height: 50px;
				font-size: 36px;
				font-weight: 300;
				text-align: center;
				color: $Black_Font_Color;
				line-height: 50px;
				letter-spacing: 2px;
			}
			p {
				height: 20px;
				font-size: 14px;
				font-weight: 300;
				text-align: center;
				color: $Black_Font_Color;
				line-height: 20px;
				letter-spacing: 1px;
				margin: 10px auto 15px;
			}
			b {
				display: flex;
				justify-content: center;
				span {
					display: block;
					margin-left: 15px;
				}
				img {
					position: relative;
					width: 26px;
					height: 18px;
				}
			}
		}
	}
}
