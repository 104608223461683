@import "@/style/common.scss";

.nameVerify {
	height: 100%;
	@include column_sb;
	.verifyTitle {
		height: 93px;
		padding: 24px;
		@include boxShadow;
		margin-bottom: 10px;
		display: flex;
		background: $white_color;
		justify-content: space-between;
		h5 {
			height: 45px;
			font-size: rem(32px);
			color: $Black_Font_Color;
			line-height: 45px;
		}
		div {
			@include column_end;
			p {
				color: $Gary_Color;
				font-size: 12px;
			}
		}
	}
	.main {
		flex: 1;
		@include column_sb;
		@include boxShadow;
		background: $white_color;
		.mainTitle {
			height: 55px;
			line-height: 55px;
			border-bottom: 1px solid #f9f9fa;
			text-align: center;
			font-size: 16px;
		}
		.mainInfo {
			flex: 1;
			width: 480px;
			height: auto;
			margin: 0 auto;
			padding-top: 40px;
			.confirmBtn {
				margin-top: 45px;
			}
			.verifyInfo {
				@include column_sb;
				margin-top: 25px;
				width: 100%;
				label {
					height: 17px;
					font-size: 12px;
					color: $Gary_Color;
				}
				p {
					height: rem(54px);
					width: 100%;
					line-height: rem(54px);
					font-size: 14px;
					background: #f5f5f5;
					border-radius: 2px;
					padding: 0 24px;
				}
			}
		}
	}
}
