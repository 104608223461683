@import "@/style/common.scss";

.accountWrapper {
	@include column_sb;
	background: #fafafa;
	overflow: auto;
	height: 100%;
	.box {
		width: $maxWidth;
		margin: auto;
	}
	main {
		margin: 20px auto;
		@include row_sb;
		justify-content: flex-start;
		align-items: initial;
		.nav {
			@include column_start;
			@include boxShadow;
			width: 200px;
			height: auto;
			margin-right: 10px;
			background: $white_color;
			padding: rem(10px) 0;
			.title {
				color: $Active_Color;
				height: 48px;
				@include row_start;
				padding-left: 20px;
				font-size: 14px;
				line-height: 48px;
				user-select: none;
				img {
					margin-right: 10px;
				}
			}
			a {
				color: #121319;
				height: 48px;
				line-height: 48px;
				padding-left: 53px;
				margin-bottom: 5px;
				font-size: 14px;
				&.active {
					position: relative;
					background: #f5f5f5;
					&:after {
						position: absolute;
						top: 0;
						left: 0;
						width: 4px;
						height: 100%;
						background: $Active_Color;
						content: "";
					}
				}
				&:hover {
					background: #f5f5f5;
				}
			}
		}
		.content {
			flex: 1;
			@include column_start;
			width: 990px;
		}
	}
}
