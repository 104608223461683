@import "@/style/common.scss";

.loginHistory {
	height: 100%;
	max-width: 990px;
	@include column_sb;
	h5 {
		font-size: rem(32px);
		height: 93px;
		line-height: 45px;
		color: $Black_Font_Color;
		background: $white_color;
		padding: 24px;
		@include boxShadow;
	}
	.main {
		flex: 1;
		padding: 24px;
		background: $white_color;
		margin-top: 10px;
		@include boxShadow;
		.title {
			height: 33px;
			border-bottom: 1px solid #f5f5f5;
			color: $Black_Font_Color;
			font-weight: 600;
		}
		.list {
			flex: 1;
			.header,
			.item {
				background: #f9f9fa;
				color: $Gary_Color;
				height: 44px;
				line-height: 44px;
				display: flex;
				font-size: 14px;
				padding: 0 15px;
				.country,
				.ip {
					width: 15%;
					b {
						display: block;
						width: 80%;
						overflow: hidden; //超出的文本隐藏
						text-overflow: ellipsis; //溢出用省略号显示
						white-space: nowrap; //溢出不换行
					}
				}
				.system {
					width: 50%;
					p {
						max-width: 90%;
						overflow: hidden; //超出的文本隐藏
						text-overflow: ellipsis; //溢出用省略号显示
						white-space: nowrap; //溢出不换行
					}
				}
				.time {
					width: 20%;
				}
			}
			.item {
				background: $white_color;
				border-bottom: 1px solid #f5f5f5;
			}
		}
	}
}
