@import "@/style/common.scss";

.commonCopy {
	@include row_center;
	height: 100%;
	width: rem(40px);
	cursor: pointer;
	font-weight: 600;
	svg {
		width: 20px;
		height: 20px;
		fill: $Active_Color;
	}
}
