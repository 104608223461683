@import "@/style/common.scss";

.recharge {
	.rechargeTitle {
		height: 93px;
		padding: 24px;
		display: flex;
		background: $white_color;
		@include boxShadow;
		h5 {
			align-self: center;
			font-size: rem(32px);
			line-height: 38px;
			font-weight: 600;
			color: #121319;
		}
	}
	.rechargeMain {
		@include boxShadow;
		margin-top: 10px;
		.switchBtn {
			display: flex;
			justify-content: flex-start;
			height: 46px;
			line-height: 46px;
			background: $white_color;
			padding: 0 24px;
			@include common_btn;
			border-bottom: 1px solid #f5f5f5;
			div {
				margin-right: 30px;
				&.active {
					border-bottom: 2px solid $Active_Color;
				}
			}
		}
	}
	.cryptoRecharge {
		display: flex;
		padding: 24px;
		background: $white_color;
		.infoRight {
			flex: 1;
			@include column_sb;
			align-items: initial;
			.selectCoin {
				@include column_sb;
				align-items: initial;
				label {
					font-size: 12px;
					line-height: 16px;
				}
				.selectBox {
					width: 100%;
					align-self: center;
					margin-top: 5px;
				}
			}
			.balance {
				font-size: 13px;
				margin-top: 20px;
				color: #121319;
				font-weight: 600;
				span {
					font-size: 12px;
				}
			}
			.tips {
				color: $Black_Font_Color;
				h6 {
					font-size: 14px;
					margin-bottom: 6px;
					font-weight: 600;
					color: #121319;
				}
				ul {
					li {
						line-height: 20px;
						font-size: 12px;
						color: #121319;
						font-weight: 600;
					}
				}
			}
		}
		.infoLeft {
			flex: 1;
			@include column_sb;
			padding-left: 30px;
			.chain {
				@include column_sb;
				label {
					font-size: 12px;
					color: $Gary_Color;
					line-height: 16px;
				}
				.chainName {
					margin-top: 5px;
					display: flex;
					> div {
						height: rem(54px);
						width: 126px;
						text-align: center;
						line-height: rem(54px);
						border-radius: 2px;
						background: #f5f5f5;
						margin-right: 20px;
						@include common_btn;
						&.active {
							background: $Active_Color;
							color: $white_color;
						}
					}
				}
			}
			.qr {
				width: 120px;
				height: 120px;
				margin: 30px auto 40px;
			}
			.address {
				background: #f5f5f5;
				font-size: 13px;
				padding: 0 20px;
				> div {
					@include row_sb;
					height: rem(54px);
					line-height: rem(54px);
					.copy {
						svg {
							width: 14px;
							height: 16px;
						}
					}
				}
			}
		}
	}
	.fiatRecharge {
		padding: 10px 24px 24px;
		background: $white_color;
		@include column_sb;
		label {
			line-height: 16px;
			color: $Gary_Color;
			font-size: 12px;
		}
		.fiatBalance {
			padding: 23px 0;
			p {
				font-size: 14px;
				line-height: 20px;
				margin-bottom: 4px;
			}
			div {
				b {
					font-size: 24px;
					color: $Active_Color;
					span {
						font-size: 12px;
						color: $Black_Font_Color;
					}
				}
				i {
					display: inline-block;
					margin-left: 20px;
					font-size: 12px;
					color: $Gary_Color;
				}
			}
		}
		.payMethod {
			label {
				display: block;
				margin-bottom: 5px;
			}
			.payBox {
				display: flex;
				flex-wrap: wrap;
				.pay {
					width: 460px;
					display: flex;
					padding: 12px 0 12px 20px;
					border: 1px solid #f5f5f5;
					border-radius: 3px;
					margin: 0 10px 20px 0;
					@include common_btn;
					@include boxShadow;
					&.active {
						border-color: $Active_Color;
					}
					&:hover {
						border-color: $Active_Color;
					}
					img {
						width: 30px;
						height: 30px;
						align-self: center;
						margin-right: 10px;
					}
					div {
						display: flex;
						justify-content: center;
						flex-direction: column;
						b {
							display: block;
							margin-bottom: 5px;
						}
						p {
							font-size: 12px;
							padding-top: 3px;
							color: $Gary_Color;
							font-weight: 500;
						}
					}
				}
			}
		}
		.payName {
			display: flex;
			justify-content: flex-start;
			.simpleInput {
				width: 100%;
			}
		}
		.payMoney {
			display: flex;
			justify-content: flex-start;
			.simpleInput {
				margin-right: 15px;
			}
			.money {
				width: 70px;
				background: #f5f5f5;
				font-size: 14px;
				height: rem(54px);
				line-height: rem(54px);
				align-self: flex-start;
				margin: 20px 15px 0;
				text-align: center;
				border-radius: 2px;
				@include common_btn;
				&.active {
					background: $Active_Color;
					color: $white_color;
				}
			}
		}
		.submitBox {
			display: flex;
			justify-content: space-between;
			margin-top: 20px;
			> div {
				flex: 1;
				&.payBtn {
					@include column_sb;
				}
				&.tips {
					display: flex;
					justify-content: flex-end;
					h6 {
						font-size: 14px;
						line-height: 20px;
					}
					p {
						font-size: 12px;
						line-height: 17px;
						a {
							color: $Active_Color;
						}
					}
				}
			}
		}
	}
	.recentRecord {
		@include boxShadow;
		height: 374px;
		padding: 24px;
		background: $white_color;
		margin-top: 10px;
		.record {
			@include row_sb;
			border-bottom: 1px solid #f5f5f5;
			h5 {
				height: 40px;
				line-height: 40px;
				font-size: 16px;
				border-bottom: 2px solid $Active_Color;
				font-weight: 600;
				color: "#121319";
			}
			a {
				font-size: 12px;
				color: $Active_Color;
				align-self: center;
			}
		}
		ul {
			flex: 1;
			position: relative;
			&.cryptoList {
				div {
					flex: none;
					width: 12%;
				}
				.longer {
					width: 40%;
				}
				li {
					@include row_sb;
					height: 44px;
					line-height: 44px;
					border-bottom: 1px solid #f5f5f5;
					padding: 0 16px;
					&.header {
						background: #f5f5f5;
						margin-top: 15px;
						color: $Gary_Color;
						font-weight: 500;
						div {
							font-size: 12px;
						}
					}
					div {
						// flex: none;
						font-size: 14px;
						&:last-child {
							text-align: right;
						}
					}
				}
			}
			li {
				@include row_sb;
				height: 44px;
				line-height: 44px;
				border-bottom: 1px solid #f5f5f5;
				padding: 0 16px;
				&.header {
					background: #f5f5f5;
					margin-top: 15px;
					color: $Gary_Color;
					font-weight: 500;
					div {
						font-size: 12px;
					}
				}
				div {
					flex: 1;
					font-size: 14px;
					&:last-child {
						text-align: right;
					}
				}
			}
		}
		.empty {
			height: 70%;
		}
	}
}
