@import "@/style/common.scss";

.inviteWrapper {
	@include column_start;
	background: #fafafa;
	header {
		background: rgba(0, 0, 0, 0.3);
		position: absolute;
		width: 100%;
	}
	.bannerBox {
		height: 366px;
		width: 100%;
		position: relative;
		img {
			width: 100%;
			height: 100%;
			top: 0;
			left: 0;
		}
		div {
			position: relative;
			z-index: 1;
			width: $maxWidth;
			height: 100%;
			margin: auto;
			@include column_center;
			position: absolute;
			left: 0;
			right: 0;
			top: 0;
			bottom: 0;
		}
		h5 {
			height: 67px;
			font-size: 48px;
			font-family: PingFangSC, PingFangSC-Semibold;
			font-weight: 600;
			text-align: left;
			color: $white_color;
			line-height: 67px;
		}
		p {
			color: $white_color;
			font-size: 14px;
			font-weight: 500;
			b {
				font-size: 40px;
				color: $Active_Color;
			}
		}
	}
	main {
		margin: auto;
		width: $maxWidth;
		height: auto;
		.section {
			height: 360px;
			background: $white_color;
			@include boxShadow;
			margin-bottom: 15px;
			h6 {
				height: 85px;
				font-size: 32px;
				font-family: PingFangSC, PingFangSC-Semibold;
				font-weight: 600;
				text-align: left;
				color: $Black_Font_Color;
				line-height: 45px;
				padding: 40px 40px 0;
			}
		}
		.stepBox {
			padding: 0 40px 40px;
			@include row_sb;
			div {
				@include column_sb;
				img {
					width: 300px;
					height: 140px;
					margin: 32px 0 15px;
				}
				b {
					font-size: 18px;
					color: $Black_Font_Color;
				}
				p {
					font-size: 14px;
					color: $Gary_Color;
					margin-top: 6px;
				}
			}
		}
		.inviteBox {
			height: 465px;
			width: 100%;
			@include row_sb;
			margin-bottom: 15px;
			.left {
				width: 100%;
				background: $white_color;
				@include boxShadow;
				padding: 40px;
				@include column_sb;
				height: 100%;
			}
			.title {
				div {
					height: 45px;
					font-size: 32px;
					font-weight: 600;
					text-align: left;
					color: $Black_Font_Color;
					line-height: 45px;
				}
				p {
					height: 20px;
					font-size: 14px;
					font-weight: 600;
					text-align: left;
					color: $Gary_Color;
					line-height: 20px;
				}
			}
			.inviteLink {
				font-size: 16px;
				font-family: PingFangSC, PingFangSC-Regular;
				font-weight: 400;
				text-align: left;
				color: $Gary_Color;
				@include column_sb;
				label {
					display: block;
					margin-bottom: 10px;
					font-weight: 600;
				}
				.codeBox {
					display: flex;
					.copy {
						width: 120px;
						height: 50px;
						border-radius: 2px;
						text-align: center;
						line-height: 50px;
						margin-left: 30px;
						color: $white_color;
						background: $Active_Color;
						@include common_btn;
					}
				}
				.link {
					@include row_start;
					height: 50px;
					line-height: 50px;
					padding: 0 30px;
					width: auto;
					color: $Black_Font_Color;
					background: #f5f5f5;
				}
			}
			.copyLink {
				width: 630px;
				height: 50px;
				text-align: center;
				line-height: 50px;
				border-radius: 2px;
				color: $white_color;
				background: $Active_Color;
				@include column_sb;
			}
		}
		.proxyBox {
			height: 224px;
			padding: 40px;
			width: 100%;
			background: $white_color;
			margin-bottom: 13px;
			@include boxShadow;
			.title {
				height: 45px;
				font-size: 32px;
				font-family: PingFangSC, PingFangSC-Semibold;
				font-weight: 600;
				text-align: left;
				color: $Black_Font_Color;
				line-height: 45px;
				margin-bottom: 30px;
			}
			.info {
				display: flex;
				> div {
					flex: 1;
					font-weight: 600;
					label {
						font-size: 14px;
						color: $Gary_Color;
						display: block;
						margin-bottom: 10px;
					}
					div {
						line-height: 50px;
						height: 50px;
						font-size: 32px;
						color: $Active_Color;
						display: flex;
						img {
							width: 40px;
							align-self: center;
						}
					}
				}
			}
		}
		.inviteTrade {
			background: $white_color;
			@include boxShadow;
			margin-bottom: 10px;
			padding: 40px;
			.title {
				@include row_sb;
				margin-bottom: 15px;
				h5 {
					font-size: 32px;
					font-weight: 600;
					color: $Black_Font_Color;
				}
				a {
					color: $Active_Color;
					font-size: 12px;
					align-self: flex-end;
				}
			}
			ol {
				li {
					display: flex;
					height: 44px;
					line-height: 44px;
					border-bottom: 1px solid #f5f5f5;
					padding: 0 12px;
					font-size: 14px;
					div {
						width: 22.5%;
						text-align: center;
						&:first-child {
							width: 10%;
						}
					}
					&.header {
						background: #f5f5f5;
						color: $Gary_Color;
						font-size: 12px;
						font-weight: 600;
					}
				}
				.empty {
					width: 100%;
					height: 400px;
					position: relative;
					div {
						margin: auto;
						position: absolute;
						width: 100px;
						height: 78px;
						top: 0;
						left: 0;
						right: 0;
						bottom: 0;
						@include column_sb;
						img {
							width: 60px;
							margin: auto;
						}
						b {
							color: #858585;
							font-size: 12px;
							text-align: center;
							font-weight: 600;
						}
					}
				}
			}
		}
		.levelBox {
			padding: 40px;
			background: $white_color;
			@include boxShadow;
			margin-bottom: 70px;
			.title {
				@include row_sb;
				font-weight: 600;
				.left {
					div {
						height: 45px;
						font-size: 32px;
						font-weight: 600;
						text-align: left;
						color: $Black_Font_Color;
						line-height: 45px;
					}
					p {
						height: 20px;
						font-size: 14px;
						text-align: left;
						color: $Gary_Color;
						line-height: 20px;
					}
				}
				.right {
					a {
						display: block;
						width: 200px;
						height: 45px;
						text-align: center;
						line-height: 45px;
						border-radius: 3px;
						background: $Active_Color;
						color: $white_color;
					}
				}
			}
			.level {
				display: flex;
				padding: 60px 0;
				div {
					flex: 1;
					@include column_sb;
					img {
						width: 90px;
						height: 117px;
						margin: 20px auto;
					}
					b {
						font-size: 18px;
						text-align: center;
					}
					span {
						text-align: center;
						font-size: 20px;
					}
					p {
						font-size: 14px;
						margin-top: 10px;
						text-align: center;
					}
				}
			}
		}
	}
}
