@import "@/style/common.scss";

.bankcard {
	@include column_sb;
	height: 100%;
	.bankcardTitle {
		height: 93px;
		padding: 24px;
		display: flex;
		justify-content: space-between;
		background: $white_color;
		@include boxShadow;
		h5 {
			align-self: center;
			font-size: rem(32px);
			line-height: 38px;
		}
		div {
			width: 120px;
			height: 32px;
			line-height: 32px;
			font-size: 12px;
			color: $white_color;
			border-radius: 3px;
			text-align: center;
			background: #f78c1c;
			@include common_btn;
		}
	}
	.bankMain {
		padding: 24px;
		flex: 1;
		background: $white_color;
		margin-top: 10px;
		@include boxShadow;
		.switchBtn {
			display: flex;
			justify-content: flex-start;
			height: 46px;
			line-height: 46px;
			@include common_btn;
			border-bottom: 1px solid #f5f5f5;
			div {
				border-bottom: 2px solid $Active_Color;
			}
		}
		.bankcardList {
			padding: 20px 0;
			display: flex;
			justify-content: flex-start;
			flex-wrap: wrap;
			max-height: 550px;
			overflow: auto;
			position: relative;
			.bankBox {
				position: relative;
				width: 293px;
				height: 150px;
				padding: 20px;
				border-radius: 4px;
				color: $white_color;
				display: flex;
				justify-content: flex-start;
				margin: 0 20px 20px 0;
				.img {
					width: 35px;
					height: 35px;
					background: $white_color;
					border-radius: 50%;
					align-self: center;
					padding: 2.5px;
					img {
						display: block;
						width: 30px;
						height: 30px;
					}
				}
				.info {
					@include column_sb;
					padding-left: 15px;
					width: 100%;
					.bankName {
						align-self: left;
						b {
							display: block;
							height: 21px;
							font-size: 15px;
							font-weight: 500;
							line-height: 21px;
							margin-bottom: 5px;
						}
						p {
							font-size: 12px;
						}
					}
					.bankcard {
						height: 26px;
						font-size: 19px;
						text-align: center;
						font-family: PingFangSC, PingFangSC-Medium;
						font-weight: 500;
						line-height: 26px;
						letter-spacing: 1px;
					}
					.btnBox {
						display: flex;
						justify-content: space-between;
						font-size: 12px;
						.right {
							display: flex;
							justify-content: flex-end;
							.btn:last-child {
								margin-left: 15px;
							}
						}
						.btn {
							padding: 3px 5px;
							border: 1px solid $white_color;
							border-radius: 1px;
							@include common_btn;
						}
					}
				}
			}
			.default {
				position: absolute;
				top: 10px;
				right: 0;
				width: 40px;
				height: 20px;
				font-size: 12px;
				line-height: 20px;
				background: url("../../../assets/userCenter/bank/default.svg") no-repeat center;
				background-size: 100%;
			}
			.empty {
				height: 460px;
			}
		}
	}
}
.icbc {
	background: url("../../../assets/userCenter/bank/bankBg/ICBC.png") no-repeat center;
	background-size: 120%;
}
.cmb {
	background: url("../../../assets/userCenter/bank/bankBg/CMB.png") no-repeat center;
	background-size: 120%;
}
.ccb {
	background: url("../../../assets/userCenter/bank/bankBg/CCB.png") no-repeat center;
	background-size: 120%;
}
.abc {
	background: url("../../../assets/userCenter/bank/bankBg/ABC.png") no-repeat center;
	background-size: 120%;
}
.boc {
	background: url("../../../assets/userCenter/bank/bankBg/BOC.png") no-repeat center;
	background-size: 120%;
}
.comm {
	background: url("../../../assets/userCenter/bank/bankBg/COMM.png") no-repeat center;
	background-size: 120%;
}
.cmbc {
	background: url("../../../assets/userCenter/bank/bankBg/CMBC.png") no-repeat center;
	background-size: 120%;
}
.spcb {
	background: url("../../../assets/userCenter/bank/bankBg/SPDB.png") no-repeat center;
	background-size: 120%;
}
.citic {
	background: url("../../../assets/userCenter/bank/bankBg/CITIC.png") no-repeat center;
	background-size: 120%;
}
.gbd {
	background: url("../../../assets/userCenter/bank/bankBg/GDB.png") no-repeat center;
	background-size: 120%;
}
.szpab {
	background: url("../../../assets/userCenter/bank/bankBg/SZPAB.png") no-repeat center;
	background-size: 120%;
}
.cib {
	background: url("../../../assets/userCenter/bank/bankBg/CIB.png") no-repeat center;
	background-size: 120%;
}
.hxb {
	background: url("../../../assets/userCenter/bank/bankBg/HXB.png") no-repeat center;
	background-size: 120%;
}
.ceb {
	background: url("../../../assets/userCenter/bank/bankBg/CEB.png") no-repeat center;
	background-size: 120%;
}
.psbc {
	background: url("../../../assets/userCenter/bank/bankBg/PSBC.png") no-repeat center;
	background-size: 120%;
}
.alipay {
	background: url("../../../assets/userCenter/bank/bankBg/Alipay.png") no-repeat center;
	background-size: 120%;
}
.zone {
	display: flex;
	justify-content: space-between;
	.simpleSelect {
		width: 45%;
	}
}
.confirmBtn {
	margin-top: 15px;
}
.editBankBox {
	display: flex;
	justify-content: flex-start;
	height: 120px;
	padding: 20px;
	margin-bottom: 25px;
	.info {
		padding-left: 15px;
		color: $white_color;
		@include column_sb;
		.bankName {
			b {
				font-size: 19px;
			}
			p {
				font-size: 12px;
			}
		}
		.bankcard {
			height: 26px;
			font-size: 19px;
			text-align: center;
			font-family: PingFangSC, PingFangSC-Medium;
			font-weight: 600;
			line-height: 26px;
			letter-spacing: 1px;
		}
	}
}
