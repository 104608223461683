@import "@/style/common.scss";

.aboutUsWrapper {
	display: block;
	height: 100%;
	background: #fafafa;
	overflow: auto;
	header {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		background: rgba(0, 0, 0, 0.3);
	}
	> img {
		position: relative;
		display: block;
		width: 100%;
		margin-bottom: 40px;
	}
	.section {
		width: 100%;
		position: relative;
		margin-bottom: 40px;
		.main {
			width: $maxWidth;
			margin: 0 auto;
			@include boxShadow;
			padding: 40px;
			background: $white_color;
			h6 {
				height: 45px;
				font-size: 32px;
				font-weight: 600;
				text-align: left;
				color: $Black_Font_Color;
				line-height: 45px;
			}
			span {
				display: block;
				height: 20px;
				font-size: 14px;
				font-weight: 600;
				text-align: left;
				color: $Gary_Color;
				line-height: 20px;
			}
			p {
				margin-top: 20px;
				font-size: 16px;
				line-height: 22px;
				color: #333;
				font-weight: 500;
			}
		}
	}
	.service {
		margin-bottom: 40px;
		.main {
			width: $maxWidth;
			padding: 40px;
			margin: auto;
			@include boxShadow;
			background: $white_color;
			h6 {
				height: 45px;
				font-size: 32px;
				font-weight: 600;
				text-align: center;
				color: $Black_Font_Color;
				line-height: 45px;
				margin-bottom: 50px;
			}
			img {
				display: flex;
				margin: auto;
			}
		}
	}
	.qualification {
		position: relative;
		width: 100%;
		height: 560px;
		.last {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
		}
		.main {
			height: 100%;
			width: $maxWidth;
			margin: auto;
			@include row_sb;
			align-items: flex-start;
			.left {
				position: relative;
				z-index: 10;
				flex: 0.55;
				color: $white_color;
				padding-top: 100px;
				h5 {
					font-size: 32px;
					font-weight: 600;
					line-height: 45px;
					color: $white_color;
					padding-bottom: 30px;
				}
				p {
					width: 560px;
					font-size: 14px;
					line-height: 24px;
				}
			}
			.right {
				position: relative;
				z-index: 10;
				flex: 0.45;
				height: 100%;
				margin-left: 20px;
				.titleImg {
					@include row_center;
					margin: 40px 0;
					img {
						width: 58px;
						height: 58px;
						margin: 0 15px;
					}
				}
				.imgBox {
					display: flex;
					.magnifier {
						align-self: center;
						margin: auto;
					}
					&.nfa {
						margin-top: 80px;
					}
				}
			}
		}
	}
	.mediaBox {
		width: $maxWidth;
		margin: 40px auto 80px;
		padding: 40px;
		background: $white_color;
		@include boxShadow;
		h6 {
			height: 45px;
			font-size: 32px;
			font-weight: 600;
			text-align: center;
			color: $Black_Font_Color;
			line-height: 45px;
			margin-bottom: 50px;
		}
		> div {
			@include row_sb;
			> img {
				flex: 0.35;
			}
			> div {
				flex: 0.6;
				display: flex;
				@include column_center;
				img {
					width: 90%;
					margin: 10px 0;
				}
			}
		}
	}
}
