@import "@/style/common.scss";

.inviteFriends {
	.inviteTitle {
		height: 93px;
		padding: 24px;
		display: flex;
		justify-content: space-between;
		background: $white_color;
		@include boxShadow;
		h5 {
			align-self: center;
			font-size: rem(32px);
			line-height: 38px;
		}
	}
	.inviteInfo {
		display: flex;
		background: $white_color;
		@include boxShadow;
		margin-top: 10px;
		padding: 24px;
		div {
			@include column_sb;
			flex: 1;
			label {
				height: 20px;
				font-size: 14px;
				font-weight: 500;
				color: $Black_Font_Color;
				line-height: 20px;
			}
			b {
				height: 29px;
				font-size: 24px;
				color: $Active_Color;
				line-height: 29px;
			}
		}
	}
	.myUser {
		background: $white_color;
		@include boxShadow;
		padding: 0 24px;
		margin-top: 10px;
	}
}
.userBox,
.tradeBox {
	.title {
		display: flex;
		height: 49px;
		justify-content: flex-start;
		margin-bottom: 5px;
		.titleSection {
			align-self: center;
			display: flex;
			margin-right: 15px;
			label {
				align-self: center;
				font-size: 12px;
				color: $Deep_Section_Bg;
				margin-right: 10px;
			}
			> input {
				height: 32px;
				line-height: 32px;
				font-size: 14px;
				width: 150px;
				border: $font_color;
				background: #f5f5f5;
				outline: none;
				padding-left: 10px;
				border-radius: 2px;
				&::placeholder {
					font-size: 12px;
				}
			}
			.dateWrapper {
				display: flex;
				span {
					align-self: center;
					&.line {
						width: 30px;
						text-align: center;
					}
				}
			}
		}
		.search,
		.excel {
			width: 100px;
			height: 32px;
			line-height: 32px;
			text-align: center;
			font-size: 12px;
			background: $Active_Color;
			color: $white_color;
			align-self: center;
			border-radius: 3px;
			margin-left: 20px;
			@include common_btn;
		}

		.excel {
			background: $font_color;
			color: $Deep_Section_Bg;
		}
	}
	ol {
		height: 484px;
		li {
			display: flex;
			height: 44px;
			line-height: 44px;
			border-bottom: 1px solid #f5f5f5;
			padding: 0 12px;
			font-size: 14px;
			div {
				flex: 1;
				text-align: left;
				&:last-child {
					text-align: right;
				}
			}
			&.header {
				background: #f5f5f5;
				color: $Gary_Color;
				font-size: 12px;
			}
		}
	}
}
.tradeBox {
	ol li {
		div {
			flex: none;
			width: 20%;
		}
	}
}
