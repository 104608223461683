@import "@/style/common.scss";

.imgCover {
	position: fixed;
	top: 0;
	left: 0;
	height: 100%;
	width: 100%;

	&.isMask {
		background: rgba(0, 0, 0, 0.5);
	}

	z-index: 99999;
	.imgBox {
		width: 300px;
		height: 150px;
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		background: #fff;
		border-radius: 4px;
		box-shadow: 0px 0px 4px 0 rgba(0, 0, 0, 0.5);
		// border: 1px solid #000;
		p {
			font-size: 16px;
			color: #323232;
			text-align: center;
			font-weight: 500;
			line-height: 14px;
			margin-top: 7px;
		}
		.code {
			padding: 27px 15px;
			overflow: hidden;
			background: #fff;
			img {
				width: 30%;
				height: 30px;
				float: left;
			}
			input {
				float: left;
				width: 60%;
				margin-left: 5%;
				height: 30px;
				line-height: 30px;
				outline: none;
				border: none;
				font-size: 14px;
				padding: 2px 2px 2px 7px;
				background: #f5f5f5;
				&::placeholder {
					color: #aaa;
				}
			}
		}
		.btnBox {
			border-top: 1px solid #f5f5f5;
			display: flex;
			background: #fff;
			border-radius: 0 0 4px 4px;
			div {
				flex: 1 1;
				text-align: center;
				font-size: 16px;
				padding: 7px;
				color: #969696;
				line-height: 28px;
				cursor: pointer;
			}
			div:last-child {
				color: #c9ac8f;
				border-left: 1px solid #f5f5f5;
				font-size: 16px;
			}
		}
	}
}
