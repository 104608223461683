@import "@/style/common.scss";

.footerWrapper {
	width: 100%;
	@include column_center;
	.content {
		width: 100%;
		background: #333333;
		.main {
			width: $maxWidth;
			margin: auto;
			padding: 85px 0 65px;
			@include row_sb;
		}
		.left {
			max-width: 700px;
			.nav {
				font-size: 18px;
				display: flex;
				color: $white_color;
				margin-bottom: 20px;
				> div {
					margin-right: 30px;
					a {
						color: $white_color;
						@include common_btn;
						&:hover {
							color: $Active_Color;
						}
					}
				}
			}
			.info {
				p {
					font-size: 14px;
					font-weight: 300;
					color: #b2b2b2;
					line-height: 26px;
					margin-left: rem(30);
				}
			}
		}
		.right {
			@include column_start;
			font-size: 14px;
			.follow {
				@include column_sb;
				span {
					color: $white_color;
					align-self: flex-start;
				}
				div {
					@include row_start;
					margin-top: 20px;
					b,
					a {
						width: 24px;
						height: 24px;
						display: inline-block;
						margin-right: 20px;
						@include common_btn;
					}
				}
			}
		}
	}
	.tips {
		background: #424242;
		font-size: 12px;
		color: #b2b2b2;
		.main {
			width: $maxWidth;
			margin: auto;
			p {
				font-weight: 300;
				line-height: 22px;
				padding: 30px 0;
			}
			> div {
				@include row_sb;
				height: 47px;
				line-height: 47px;
				border-top: 1px solid rgba(242, 242, 242, 0.1);
				padding-top: rem(10);
			}
		}
	}
}
