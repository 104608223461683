@import "@/style/common.scss";

.announcementWrapper {
	overflow: auto;
	height: 100%;
	background-color: #f1f1f1;
	main {
		margin: auto;
		width: 1200px;
		.content {
			width: 100%;
		}
	}
	.announceNotice {
		.title {
			font-size: 32px;
			padding: 48px 0 18px 28px;
			font-family: PingFangSC-Medium, self;
		}
		.list {
			display: flex;
			flex-direction: column;
			flex-wrap: nowrap;
			background-color: #fff;
			padding: 28px 0;
			.item {
				display: flex;
				flex-wrap: nowrap;
				h2 {
					font-weight: bold;
					font-size: 38px;
					font-family: din-alternate-bold, self;
				}

				h5 {
					font-size: 14px;
				}
				.year {
					flex: 0 1 73px;
					margin: 0 34px 0 28px;

					h2 {
						color: #e6e6e6;
					}
				}
				.month {
					flex: 0 1 36.5px;
					display: flex;
					flex-wrap: nowrap;
					margin: 0 34px 0 0;

					h2 {
						color: #b3b3b3;
					}
				}
				.date {
					flex: 1;
					padding: 0 42px;
					font-family: PingFangSC-Medium, self;

					h5 {
						margin-bottom: 16px;
					}

					a {
						color: #f78c1c;
						font-size: 20px;

						&:hover {
							color: #6c6c6c;
							cursor: pointer;
						}
					}

					hr {
						background-color: #f2f2f2;
						height: 1px;
						border: none;
						margin: 24px 0;
					}
				}
			}
		}
	}
	.pagination {
		width: 100%;
		display: flex;
		flex-direction: row;
		justify-content: center;
		align-items: center;
		margin: 42px;
		.totalPage {
			margin-left: 24px;
			color: #323232;
		}

		.firstPage {
			text-align: center;
			user-select: none;
			font-weight: 100;
			font-size: 14px;
			border-radius: 0;
			background-color: #d8d8d8;
			color: #323232;
			height: 40px;
			min-width: 60px;
			line-height: 38px;
			margin: 0 5px;
			font-family: PingFangTC, PingFangTC-Regular;
			@include common_btn;
		}
	}
	.announceDetail {
		.header {
			font-size: 16px;
			color: #f78c1c;
			padding: 64px 0 18px 34px;
			font-family: PingFangSC-Semibold, self;
			cursor: pointer;
			&::before {
				content: "";
				display: inline-block;
				width: 11px;
				height: 11px;
				border-style: solid;
				border-width: 0 0 3px 3px;
				border-color: #252525;
				border-bottom-left-radius: 2px;
				transform: rotate(45deg);
				margin-right: 5px;
			}
		}
	}
	.detail {
		display: flex;
		flex-direction: column;
		flex-wrap: nowrap;
		background-color: #fff;
		padding: 30px 34px;
		font-family: PingFangSC-Medium, self;
		margin-bottom: 110px;
		.title {
			font-size: 28px;
			color: #252525;
		}
		.subTitle {
			padding: 18px 0;
			font-size: 12px;
			color: #999999;
		}
		.html {
			padding: 16px 0;
			font-size: 16px;
			overflow: auto;
			@include column_sb;
			div {
				line-height: 24px;
				// padding: 6px 0;
			}
		}
		hr {
			background-color: #f2f2f2;
			height: 1px;
			border: none;
			margin: 8px 0;
		}
	}
}
