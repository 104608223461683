@import "@/style/common.scss";

.myAssets {
	height: 100%;
	@include column_start;
	.assetTitle {
		height: 93px;
		padding: 24px;
		@include boxShadow;
		margin-bottom: 10px;
		display: flex;
		background: $white_color;
		justify-content: space-between;
		h5 {
			height: 45px;
			font-size: rem(32px);
			color: $Black_Font_Color;
			line-height: 45px;
			font-weight: 600;
		}
	}
	.assetBox {
	}
	.assetInfo {
		@include boxShadow;
		height: 100px;
		background: $white_color;
		margin-bottom: 10px;
		display: flex;
		justify-content: space-between;
		padding: 24px;
		.left {
			@include column_sb;
			p {
				font-size: 14px;
				color: $Deep_Section_Bg;
				font-weight: 500;
				display: flex;
				justify-content: flex-start;
				label {
					align-self: center;
				}
				b {
					display: flex;
					font-size: 12px;
					color: $font_color;
					align-self: center;
					margin-left: 20px;
					@include common_btn;
					svg {
						width: 20px;
						height: 20px;
						fill: $font_color;
					}
					span {
						align-self: center;
						margin-left: 3px;
					}
				}
			}
			> b {
				height: 29px;
				font-size: 24px;
				color: $Active_Color;
				line-height: 29px;
				span {
					font-size: 12px;
					color: $Deep_Section_Bg;
				}
			}
		}
		.right {
			display: flex;
			a {
				display: inline-block;
				width: 88px;
				height: 32px;
				text-align: center;
				line-height: 32px;
				background: $Active_Color;
				color: $white_color;
				margin-right: 20px;
				border-radius: 3px;
				font-size: 14px;
				font-weight: 500;
				&:last-child {
					border: 1px solid $Active_Color;
					background: $white_color;
					color: $Active_Color;
				}
			}
		}
	}
}
