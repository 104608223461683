// @import "~antd/dist/antd.css";
@import "./normalize.css";
html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
	border: 0;
	font-size: 100%;
	vertical-align: baseline;
	margin: 0;
	padding: 0;
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
	display: block;
}

body {
	line-height: 1;
}

ol,
ul {
	list-style: none;
}

blockquote,
q {
	quotes: none;
}

blockquote:before,
blockquote:after,
q:before,
q:after {
	content: none;
}

table {
	border-collapse: collapse;
	border-spacing: 0;
}

@import "./common.scss";

@font-face {
	font-family: "cereal";
	src: url("./AirbnbCereal-Light.ttf");
	font-weight: 100;
}
@font-face {
	font-family: "cereal";
	src: url("./AirbnbCereal-Book.ttf");
	font-weight: 300;
}
@font-face {
	font-family: "cereal";
	src: url("./AirbnbCereal-Medium.ttf");
	font-weight: 500;
}
@font-face {
	font-family: "cereal";
	src: url("./AirbnbCereal-Bold.ttf");
	font-weight: 700;
}
@font-face {
	font-family: "cereal";
	src: url("./AirbnbCereal-ExtraBold.ttf");
	font-weight: 900;
}

@font-face {
	font-family: "din-alternate-bold";
	src: url("./DIN_Alternate_Bold.ttf");
}

a {
	text-decoration: none;
	color: $white_color;
}

* {
	text-decoration: none;
	box-sizing: border-box;
	margin: 0;
	padding: 0;
	// font-family: "cereal", PingFang SC, Noto Sans SC, Microsoft YaHei, NSimSun, Ping, arial, Menlo, Monaco, Consolas,
	// 	Courier New, monospace, sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

* {
	-webkit-touch-callout: none;
	/* iOS Safari */
	-webkit-user-select: none;
	/* Safari */
	-khtml-user-select: none;
	/* Konqueror HTML */
	-moz-user-select: none;
	/* Firefox */
	-ms-user-select: none;
	/* Internet Explorer/Edge */
	user-select: none;
}

input,
select,
option {
	-webkit-touch-callout: auto;
	/* iOS Safari */
	-webkit-user-select: auto;
	/* Safari */
	-khtml-user-select: auto;
	/* Konqueror HTML */
	-moz-user-select: auto;
	/* Firefox */
	-ms-user-select: auto;
	/* Internet Explorer/Edge */
	user-select: auto;
}

input {
	border: 0;
}

h1,
h2,
h3,
h4,
h5,
h6 {
	margin: 0;
	color: rgba(0, 0, 0, 0.85);
	font-weight: 500;
}
*,
:after,
:before {
	-webkit-box-sizing: border-box;
	box-sizing: border-box;
}
body {
	color: rgba(0, 0, 0, 0.65);
}
