@import "@/style/common.scss";

.headerWrapper {
	@include row_sb;
	background: $Black_Font_Color;
	height: 66px;
	font-size: 14px;
	position: relative;
	z-index: 999;
	padding: 0 20px;
	font-weight: 500;
	.headerLeft {
		display: flex;
		a {
			padding: 0 10px;
			line-height: 66px;
			display: flex;
			justify-content: center;
			color: $white_color;
			@include common_btn;

			img {
				align-self: center;
			}
			&.active {
				color: $Active_Color;
			}
			&:hover {
				color: $Active_Color;
			}
		}
	}
	.headerRight {
		display: flex;
		.btnBox {
			display: flex;
			a {
				width: 80px;
				height: 26px;
				text-align: center;
				line-height: 26px;
				color: $white_color;
				background: linear-gradient(90deg, #ff0002, #f78c1c);
				border: 2px;
				margin: 0 10px;
				align-self: center;
				border-radius: 2px;
				&:last-child {
					background: $Black_Font_Color;
				}
			}
		}
		> a {
			@include row_center;
			width: 56px;
			position: relative;
			cursor: pointer;
			svg {
				width: 16px;
				height: 16px;
				fill: $white_color;
			}
			&:hover {
				svg {
					fill: $Active_Color;
				}
			}
			.unread {
				position: absolute;
				top: 0;
				right: 12px;
				width: 12px;
				height: 16px;
				line-height: 16px;
				font-size: 12px;
				text-align: center;
				border-radius: 5px;
				color: $white_color;
				background: $Active_Color;
			}
		}
	}
}
.headerModal {
	top: 66px !important;
	background: #252525;
	box-shadow: 1px 2px 4px 0 rgba(0, 0, 0, 0.08);
}
.tradeNav {
	position: relative;
	@include column_sb;
	padding: 10px 0;
	width: 160px;

	a {
		height: 40px;
		@include row_center;
		justify-content: flex-start;
		font-size: 14px;
		padding-left: 25px;
		line-height: 40px;
		color: $white_color;
		img {
			width: 12px;
			height: 12px;
			margin-right: 8px;
		}
		svg {
			width: 12px;
			height: 12px;
			fill: $white_color;
			margin-right: 8px;
		}
		&.active {
			color: $Active_Color;
			svg {
				fill: $Active_Color;
			}
		}
		&:hover {
			color: $Active_Color;
			background: #333;
			svg {
				fill: $Active_Color;
			}
		}
	}
	.textIcon {
		width: 60px;
		height: 23px;
	}
}
.download {
	display: flex;
	padding: 15px;
	img {
		width: 80px;
		height: 80px;
	}
	div {
		margin-left: 15px;
		@include column_sb;
		b {
			color: $Active_Color;
		}
		p {
			color: $white_color;
			font-size: 12px;
		}
		a {
			display: block;
			height: 26px;
			width: 80px;
			line-height: 26px;
			border-radius: 2px;
			font-size: 12px;
			text-align: center;
			color: $white_color;
			background: $Active_Color;
		}
	}
}
.userNav {
	position: relative;
	@include column_sb;
	padding: 10px 0;
	> div {
		margin-bottom: 10px;
		border-bottom: 1px solid $Black_Font_Color;
		line-height: 0;
		height: 60px;
		padding: 10px 25px;
		b,
		p {
			line-height: 20px;
			font-size: 14px;
			display: block;
			color: $white_color;
			user-select: none;
		}
		p {
			font-size: 12px;
			color: $Gary_Color;
		}
	}
	a {
		height: 40px;
		width: 200px;
		font-size: 14px;
		font-weight: 600;
		padding: 0 25px;
		line-height: 40px;
		color: $white_color;
		&.active {
			color: $Active_Color;
		}
		&:hover {
			color: $Active_Color;
			background: #333;
		}
	}
}

.messageBox {
	@include column_sb;
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	width: 520px;
	height: 600px;
	margin: auto;
	background: $white_color;
	z-index: 10002;
	border-radius: 4px;
	font-size: 14px;
	font-weight: 600;
	.head {
		width: 100%;
		height: 50px;
		line-height: 50px;
		@include row_sb;
		padding: 0 24px;
		box-shadow: 0 0 2px $font_color;
		b {
			font-size: 20px;
		}
		img {
			align-self: center;
			width: 14px;
			height: 14px;
			cursor: pointer;
		}
	}
	.list {
		padding: 24px 24px 10px;
		height: 450px;
		flex: 1;
		overflow: auto;
		.item {
			margin-bottom: 30px;
			.info {
				display: flex;
				justify-content: flex-start;
				img {
					width: 40px;
					height: 40px;
				}
				p {
					background: rgba(178, 178, 178, 0.4);
					color: $Deep_Section_Bg;
					margin-left: 20px;
					padding: 10px 15px;
					border-radius: 3px;
					line-height: 22px;
				}
			}
			.time {
				display: flex;
				justify-content: flex-end;
				font-size: 12px;
				color: $Gary_Color;
				margin-top: 10px;
			}
		}
	}
}
