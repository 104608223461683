@import "@/style/common.scss";

.selectWrapper {
  @include column_sb;
  margin-bottom: 25px;
  .title {
    @include row_sb;
    font-size: 12px;
    height: 16px;
    label {
      align-self: center;
      color: $Gary_Color;
    }
    a {
      color: $Active_Color;
      align-self: center;
    }
  }
}
